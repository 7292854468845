// PropType
import PropTypes from "prop-types";

// mui
import {TextField} from "@mui/material";

// react hook form
import {Controller, useFormContext} from "react-hook-form";
import {makeStyles} from "@mui/styles";


VSelect.propTypes = {
    name: PropTypes.string.isRequired
}


const useStyles = makeStyles({
    root: {
        borderRadius: 4,
        backgroundColor: "rgba(255, 255, 255, 0.1)"
    },
    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    paper: {
        boxShadow: "none",
        backgroundColor: "#262626"
    }
});

export default function VSelect({name, renderValue, ...other}) {
    const {control} = useFormContext();
    const classes = useStyles();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <TextField
                    variant={"filled"}
                    {...field}
                    InputProps={{classes}}
                    InputLabelProps={{sx: {color: "text.primary"}}}
                    SelectProps={{
                        renderValue: renderValue,
                        MenuProps: {
                            classes: {paper: classes.paper}
                        },

                    }}
                    select
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                />
            )}
        />
    )
}