function path(root, subLink) {
  return `${root}${subLink}`;
}

const ROOT_AUTH = "/auth";
const ROOT_DASHBOARD = "/dashboard/";

// ---------------------------------------------------------
export const ROOT_PATHS = {
  emailOTP: "/subscriptions-otp-setup",
  subscriptionKYC: "/subscription-kyc",
  subscriptionComplete: "/subscription-activated",
};

export const AUTH_PATHS = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, "/login"),
  register: path(ROOT_AUTH, "/register"),
  forgotPassword: path(ROOT_AUTH, "/forgot-password"),
  resetPassword: path(ROOT_AUTH, "/reset-password"),
};

export const DASHBOARD_PATHS = {
  root: ROOT_DASHBOARD,
  wineListing: path(ROOT_DASHBOARD, "/wine-listing"),
  getSupport: path(ROOT_DASHBOARD, "support"),
  profile: path(ROOT_DASHBOARD, "profile"),
};
