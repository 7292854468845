import { useEffect, useState } from "react";

// mui
import { Box, Typography, Stack, useMediaQuery, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

// components
import Page from "src/components/Page";
import Logo from "src/components/Logo";

// yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// hooks
import { FormProvider, VTextField } from "src/components/form-hooks";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router";

import { ROOT_PATHS } from "src/routes/path";
import useResendOtpButton from "src/hooks/useResendOtpButton";

export default function EmailOTP() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { user, requestOTP, verifyOTP } = useAuth();
  const { isDisabled, handleResend, countDown } = useResendOtpButton();

  const navigate = useNavigate();

  const maxInnerSx = isDesktop
    ? { maxWidth: "500px", mx: 32.75, textAlign: "center" }
    : {};

  const schema = Yup.object().shape({
    otp: Yup.string().required("Please enter the one-time password"),
  });
  const defaultValue = {
    otp: "",
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValue,
  });

  useEffect(async () => {
    if (user) {
      await requestOTP("email");
    }
  }, [user]);

  const {
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = methods;

  const handleVerifyOTP = async (data) => {
    const { otp } = data;
    const verifyData = {
      one_time_password: otp,
      purpose: "subscription",
    };
    try {
      await verifyOTP(verifyData);
      navigate(ROOT_PATHS.subscriptionKYC);
    } catch (error) {
      setError("otp", {
        type: "custom",
        message: "Incorrect OTP entered. Please try again.",
      });
    }
  };

  return (
    <Page sx={{ p: 2 }} title={"Email OTP"}>
      <FormProvider onSubmit={handleSubmit(handleVerifyOTP)} methods={methods}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          {isDesktop && (
            <Logo
              sx={{ alignSelf: "start", width: 80, height: 40 }}
              disabledLink={true}
            />
          )}
          <Stack
            mx={"auto"}
            spacing={4}
            alignItems={"center"}
            justifyContent={"center"}
            my={3}
            sx={{ ...maxInnerSx }}
          >
            {!isDesktop && (
              <Logo
                sx={{ alignSelf: "start", width: 80, height: 40 }}
                disabledLink={true}
              />
            )}
            <Box>
              <Typography
                fontFamily={"Minion Pro"}
                mb={"8px"}
                fontSize={28}
                variant={"subtitle1"}
                lineHeight={"32px"}
              >
                2-Step Verification: Set Up
              </Typography>
              <Typography fontFamily={"Minion Pro"}>
                To add an extra layer of security, some of your actions on
                Vinuage’s platform will require you to use a 2-step verification
                process that involves a one-time password (OTP) sent to your
                registered email address.
                <br />
                <br />
                We have sent an OTP to your registered email address: "
                {user?.email}". Please enter the OTP below to continue.
              </Typography>
            </Box>
            <VTextField
              sx={{ width: isDesktop ? "327px" : "100%" }}
              name={"otp"}
              label={"One-Time Password"}
            />
            <Box sx={{ maxWidth: isDesktop ? "327px" : "100%", width: "100%" }}>
              <LoadingButton
                type={"submit"}
                fullWidth
                variant="contained"
                sx={{ mb: 3, "&.Mui-disabled": { backgroundColor: "#6E6E6E" } }}
                loading={isSubmitting}
              >
                Submit & Continue
              </LoadingButton>
              <Button
                disabled={isDisabled}
                onClick={handleResend}
                fullWidth
                variant="text"
                sx={{
                  color: "rgba(255, 255, 255, 0.4)",
                  fontWeight: "400",
                }}
              >
                Resend OTP ({countDown}s)
              </Button>
              {/*<Button onClick={() => {*/}
              {/*}} color="primary" variant="text">*/}
              {/*    Resend OTP (10s)*/}
              {/*</Button>*/}
            </Box>
          </Stack>
        </Stack>
      </FormProvider>
    </Page>
  );
}
