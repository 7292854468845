import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices

import layoutsReducer from 'src/redux/slices/layouts';
import productReducer from 'src/redux/slices/product';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const layoutsPersistConfig = {
  key: 'layouts',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ['sortBy', 'checkout'],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
}

const rootReducer = combineReducers({
    layouts: persistReducer(layoutsPersistConfig, layoutsReducer),
    product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
