import { SvgIcon } from "@mui/material";

export default function VinuagePointIcon({
  width = "12px",
  height = "12px",
  color = "white",
}) {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9.5" stroke={color} fill="none" />
      <path
        d="M8.9177 9.36765C8.6726 8.79902 8.44221 8.41176 8.22652 8.20588C8.01083 7.9902 7.70201 7.86274 7.30005 7.82353V7.52941C7.34907 7.52941 7.4226 7.52451 7.52064 7.51471C7.60887 7.5049 7.70201 7.5 7.80005 7.5C8.29024 7.5 8.76083 7.62745 9.21181 7.88235C9.66279 8.12745 10.0451 8.62255 10.3589 9.36765L12.8736 15.2941L13.7412 13.2794C14.153 12.3284 14.9422 10.451 16.1089 7.64706H16.8148L12.5942 17.5H12.3295L8.9177 9.36765Z"
        fill={color}
      />
    </SvgIcon>
  );
}
