import { SvgIcon } from "@mui/material";

export default function BottomNavBuywineIcon({width="24px", height="24px"}) {
  return (
    <SvgIcon sx={{width: width, height: 24}} viewBox="0 0 25 24" fill={"none"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89999 2.75V5.38197C8.89999 6.04482 8.52549 6.65078 7.93262 6.94721L7.59098 7.11803C7.1675 7.32977 6.89999 7.7626 6.89999 8.23607V9.5H5.39999V8.23607C5.39999 7.19444 5.9885 6.24222 6.92016 5.77639L7.2618 5.60557C7.34649 5.56322 7.39999 5.47666 7.39999 5.38197V2.5C7.39999 1.80964 7.95964 1.25 8.64999 1.25H10.65C11.3403 1.25 11.9 1.80964 11.9 2.5V5.38197C11.9 5.47666 11.9535 5.56322 12.0382 5.60557L12.3798 5.77639C13.3115 6.24222 13.9 7.19445 13.9 8.23607V9.5H12.4V8.23607C12.4 7.7626 12.1325 7.32977 11.709 7.11803L11.3674 6.94721C10.7745 6.65078 10.4 6.04482 10.4 5.38197V2.75H8.89999ZM15.4 2.75V5H13.9V2.5C13.9 1.80964 14.4596 1.25 15.15 1.25H17.15C17.8403 1.25 18.4 1.80964 18.4 2.5V5.38197C18.4 5.47666 18.4535 5.56322 18.5382 5.60557L18.8798 5.77639C19.8115 6.24222 20.4 7.19445 20.4 8.23607V9.5H18.9V8.23607C18.9 7.7626 18.6325 7.32977 18.209 7.11803L17.8674 6.94721C17.2745 6.65078 16.9 6.04482 16.9 5.38197V2.75H15.4ZM3.14999 12C3.14999 11.0335 3.9335 10.25 4.89999 10.25H20.9C21.8665 10.25 22.65 11.0335 22.65 12V21C22.65 21.9665 21.8665 22.75 20.9 22.75H4.89999C3.9335 22.75 3.14999 21.9665 3.14999 21V12ZM19.9 17.25H5.89999V15.75H19.9V17.25ZM6.89999 15C7.45228 15 7.89999 14.5523 7.89999 14C7.89999 13.4477 7.45228 13 6.89999 13C6.34771 13 5.89999 13.4477 5.89999 14C5.89999 14.5523 6.34771 15 6.89999 15ZM19.9 14C19.9 14.5523 19.4523 15 18.9 15C18.3477 15 17.9 14.5523 17.9 14C17.9 13.4477 18.3477 13 18.9 13C19.4523 13 19.9 13.4477 19.9 14ZM6.89999 20C7.45228 20 7.89999 19.5523 7.89999 19C7.89999 18.4477 7.45228 18 6.89999 18C6.34771 18 5.89999 18.4477 5.89999 19C5.89999 19.5523 6.34771 20 6.89999 20ZM19.9 19C19.9 19.5523 19.4523 20 18.9 20C18.3477 20 17.9 19.5523 17.9 19C17.9 18.4477 18.3477 18 18.9 18C19.4523 18 19.9 18.4477 19.9 19Z"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}
