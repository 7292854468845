import { SvgIcon } from "@mui/material";

export default function CellarIcon({width="24px", height="24px"}) {
  return (
    <SvgIcon sx={{width: width, height: height}} viewBox="0 0 25 24" fill={"none"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.95007 10.5C2.95007 5.66751 6.86758 1.75 11.7001 1.75H13.7001C18.5326 1.75 22.4501 5.66751 22.4501 10.5V20.75H23.7001V22.25H22.4501H21.7001H17.7001H16.9501H8.45007H7.70007H3.70007H2.95007H1.70007V20.75H2.95007V10.5ZM4.45007 20.75H6.95007V18.25H4.45007V20.75ZM4.45007 16.75H6.95007V14.25H4.45007V16.75ZM4.45007 12.75V10.5C4.45007 10.2917 4.45886 10.0854 4.47609 9.88149L7.02576 10.5647C6.97597 10.8691 6.95007 11.1815 6.95007 11.5V12.75H4.45007ZM11.7001 3.25C11.5576 3.25 11.4162 3.25411 11.2758 3.26221L12.5511 5.75189L12.5996 5.75086L13.8796 3.25218C13.8199 3.25073 13.7601 3.25 13.7001 3.25H11.7001ZM4.75766 8.40403C5.04805 7.44084 5.53341 6.56219 6.16786 5.81393L8.47045 7.60473C8.05771 8.05267 7.71522 8.56633 7.46046 9.12824L4.75766 8.40403ZM9.65204 6.62344L7.26332 4.76566C7.98688 4.20501 8.81943 3.77827 9.72379 3.52263L10.9963 6.00663C10.5185 6.15463 10.0675 6.36323 9.65204 6.62344ZM15.4564 3.46418L14.1861 5.94387C14.716 6.08524 15.2159 6.30043 15.6739 6.57773L18.0698 4.71434C17.3032 4.13443 16.4177 3.70337 15.4564 3.46418ZM19.1791 5.75188L16.8737 7.54483C17.3115 8.00668 17.6735 8.54114 17.9397 9.12827L20.6425 8.40406C20.344 7.41411 19.8396 6.51345 19.1791 5.75188ZM20.9241 9.88153L18.3744 10.5647C18.4242 10.8691 18.4501 11.1815 18.4501 11.5V12.75H20.9501V10.5C20.9501 10.2917 20.9413 10.0854 20.9241 9.88153ZM18.4501 16.75V14.25H20.9501V16.75H18.4501ZM20.9501 18.25H18.4501V20.75H20.9501V18.25ZM16.9501 20.75V11.5C16.9501 9.4087 15.4396 7.6702 13.4501 7.31597V20.75H16.9501ZM11.9501 20.75V7.31597C9.96057 7.6702 8.45007 9.4087 8.45007 11.5V20.75H11.9501ZM9.95007 16.5V13.5H11.4501V16.5H9.95007ZM13.9501 13.5V16.5H15.4501V13.5H13.9501Z"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}
