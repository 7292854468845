import { SvgIcon } from "@mui/material";

export default function EditIcon({ width = "11px", height = "10px" }) {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L10.3536 2.64645C10.5488 2.84171 10.5488 3.15829 10.3536 3.35355L3.85355 9.85355C3.75979 9.94732 3.63261 10 3.5 10H1C0.723858 10 0.5 9.77614 0.5 9.5V7C0.5 6.86739 0.552678 6.74021 0.646447 6.64645L7.14645 0.146447ZM1.5 7.20711V9H3.29289L9.29289 3L7.5 1.20711L1.5 7.20711Z"
        fill="#D80998"
      />
    </SvgIcon>
  );
}
