import { SvgIcon } from "@mui/material";

export default function BottomNavPortfolioIcon({width="24px", height="24px"}) {
  return (
    <SvgIcon sx={{width: width, height: height}} viewBox="0 0 25 24" fill={"none"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 4.5H20.5C20.7761 4.5 21 4.72386 21 5V19.5H18V5C18 4.72386 18.2239 4.5 18.5 4.5ZM16.5 5C16.5 3.89543 17.3954 3 18.5 3H20.5C21.6046 3 22.5 3.89543 22.5 5V19.5V19.75H23V21.25H2V19.75H2.5V19.5V14C2.5 12.8954 3.39543 12 4.5 12H6.5C7.60457 12 8.5 12.8954 8.5 14V19.5V19.75H9.5V19.5V11C9.5 9.89543 10.3954 9 11.5 9H13.5C14.6046 9 15.5 9.89543 15.5 11V19.5V19.75H16.5V19.5V5ZM11.5 10.5H13.5C13.7761 10.5 14 10.7239 14 11V19.5H11V11C11 10.7239 11.2239 10.5 11.5 10.5ZM6.5 13.5H4.5C4.22386 13.5 4 13.7239 4 14V19.5H7V14C7 13.7239 6.77614 13.5 6.5 13.5Z"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}
