// redux
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "src/redux/store";

// axios
import axios, { serializeParams } from "src/utils/axios";

const initialState = {
  addToCartSuccess: null,
  removeCartProducts: false,
  products: {
    data: [],
    pagination: null,
    metadata: { filter_configs: [], applied_filters: [] },
    config: { sort: "name" },
  },
  filteredProducts: null,
  cart: { data: [], pagination: null },
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getProductsSuccess(state, action) {
      const {
        results,
        pagination,
        metadata,
        config,
        filtered,
        isPaginatedRequest,
      } = action.payload;
      state.isLoading = false;

      // Determine the list of products
      const newProductsData = isPaginatedRequest
        ? [...state.products.data, ...results]
        : results;

      if (filtered) {
        state.filteredProducts = {
          data: newProductsData,
          pagination,
          metadata,
          config,
        };
      }

      // Update the state.products
      state.products = {
        data: newProductsData,
        pagination,
        metadata,
        config,
      };

      if (!filtered) {
        state.filteredProducts = null;
      }
    },
    getCartProductsSuccess(state, action) {
      const { results } = action.payload;
      state.isLoading = false;
      state.cart = { data: results };
    },
    addCartProductSuccess(state, action) {
      const { product } = action.payload;
      state.isLoading = false;
      state.addToCartSuccess = product.id;
    },
    removeCartProductsSuccess(state, payload) {
      state.isLoading = false;
      state.removeCartProducts = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export function getProducts(
  params = { sort: "name" },
  filtering = false,
  nextPage = null
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const url = nextPage || "/products/";
      const response = await axios.get(url, {
        params: serializeParams(params),
      });
      const { data } = response;
      dispatch(
        slice.actions.getProductsSuccess({
          ...data,
          config: params,
          filtered: filtering,
          isPaginatedRequest: nextPage !== null,
        })
      );
    } catch (error) {
      console.log("error occurred getting products");
      console.log(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCartProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/carts/items/");
      const { data } = response;
      dispatch(slice.actions.getCartProductsSuccess(data));
    } catch (error) {
      console.log({ error });
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCartProducts(data, config) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post("/carts/items/", { ...data });
      dispatch(slice.actions.addCartProductSuccess(response.data));
      if (response.status === 201) {
        if (config) {
          dispatch(getProducts(config));
        }
        const response = await axios.get("/carts/items/");
        const { data } = response;
        dispatch(slice.actions.getCartProductsSuccess(data));
      }
      return response;
    } catch (error) {
      throw error;
    }

  };
}

export function removeCartProducts(id, config) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/carts/items/${id}/`);
      if (response.status === 204) {
        const response = await axios.get("/carts/items/");
        const { data } = response;
        dispatch(slice.actions.getCartProductsSuccess(data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function checkoutCartProducts(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/carts/checkout/", { ...data });
      return response;
    } catch (errorResponse) {
      return errorResponse;
    }
  };
}
