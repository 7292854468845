import { SvgIcon } from "@mui/material";

export default function VisibilityIcon(props) {
  return (
    <SvgIcon
      height={"24px"}
      width={"24px"}
      viewBox={"0 0 24 24"}
      fill={"none"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C15.802 18 19.0064 15.8815 20.8214 12C19.0064 8.11847 15.802 6 12 6C8.19801 6 4.9936 8.11847 3.17857 12C4.9936 15.8815 8.19801 18 12 18ZM23 12C21 17 16.9706 20 12 20C7.02944 20 3 17 1 12C3 7 7.02944 4 12 4C16.9706 4 21 7 23 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
        fill="white"
      />
    </SvgIcon>
  );
}
