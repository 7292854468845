import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// components
import LoadingScreen from "src/components/LoadingScreen";
import GuestGuard from "src/guards/GuestGuard";
import AuthGuard from "src/guards/AuthGuard";
import AuthLayout from "src/layouts/auth";
import DashboardLayout from "src/layouts/dashboard";
// paths
import { DASHBOARD_PATHS } from "src/routes/path";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  const [subscriptionStatus, setSubscriptionStatus] = useState(false);

  useEffect(() => {
    // Check if payment popup was canceled
    if (
      user &&
      user.subscription &&
      user.subscription.setup_status.payment === true
    ) {
      setSubscriptionStatus(true);
    }
  }, [user]);

  return useRoutes([
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "login/verify/",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <GuestGuard>
              <ForgetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "/",
      element: <Navigate to={DASHBOARD_PATHS.root} />,
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Dashboard /> },
        { path: "wine-listing", element: <WineListing /> },
        { path: "cart/checkout/", element: <OrderSummary /> },
        { path: "profile/profile-details", element: <ProfileDetails /> },
        {
          path: "profile/terminate-subscription",
          element: <TerminateSubscription />,
        },
        { path: "profile/support/cancel-account", element: <CancelAccount /> },
        { path: "profile/support", element: <GetSupport /> },
        { path: "profile/update-password", element: <UpdatePassword /> },
        { path: "profile/", element: <Profile /> },
        { path: "wallet/wallet-details/", element: <WalletDetails /> },
        { path: "my-cellar/", element: <MyCellar /> },
        { path: "portfolio/", element: <Portfolio /> },
        { path: "listed-wines/", element: <ListedWines /> },
        { path: "request-submitted/", element: <RequestSubmitted /> },
        { path: "delivery-request/", element: <DeliveryRequest /> },
        {
          path: "delivery-request-submitted/",
          element: <DeliveryRequestSubmitted />,
        },
        {
          path: "bar-details/",
          element: <BarDetails />,
        },
      ],
    },
    {
      path: "subscriptions-otp-setup",
      element: subscriptionStatus ? (
        <EmailOTP />
      ) : (
        <Navigate to="/dashboard" replace />
      ),
    },
    {
      path: "make-payment",
      element: <Payment />,
    },
    {
      path: "subscription-activated/",
      element: subscriptionStatus ? (
        <SubscriptionActivated />
      ) : (
        <Navigate to="/dashboard" replace />
      ),
    },
    {
      path: "subscription-kyc/",
      element: subscriptionStatus ? (
        <SubscriptionKYC />
      ) : (
        <Navigate to="/dashboard" replace />
      ),
    },
    {
      path: "update-passowrd-request-submitted/",
      element: <PassowrdResetLinkSent />,
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Auth
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ForgetPassword = Loadable(
  lazy(() => import("../pages/auth/ForgetPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const EmailOTP = Loadable(lazy(() => import("../pages/auth/EmailOTP")));
// Dashboard
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/index.js")));
const WineListing = Loadable(
  lazy(() => import("../pages/dashboard/WineListing"))
);
const OrderSummary = Loadable(
  lazy(() => import("../pages/dashboard/OrderSummary"))
);

const Payment = Loadable(
  lazy(() => import("../pages/dashboard/checkout/payment"))
);
const SubscriptionKYC = Loadable(
  lazy(() => import("../pages/dashboard/SubscriptionKYC"))
);

const SubscriptionActivated = Loadable(
  lazy(() => import("../pages/dashboard/SubscriptionActivated"))
);

const Profile = Loadable(lazy(() => import("../pages/dashboard/Profile")));

const WalletDetails = Loadable(
  lazy(() => import("../components/wallet/Wallet-Details"))
);

const MyCellar = Loadable(
  lazy(() => import("../components/my_cellar/MyCellar"))
);

const Portfolio = Loadable(
  lazy(() => import("../components/portfolio/Portfolio"))
);
const ListedWines = Loadable(
  lazy(() => import("../components/my_cellar/cellar-sell-flow/ListedWines"))
);

const ProfileDetails = Loadable(
  lazy(() => import("../components/userProfile/profileDetails/ProfileDetails"))
);

const GetSupport = Loadable(
  lazy(() => import("../components/userProfile/support/GetSupport"))
);

const CancelAccount = Loadable(
  lazy(() =>
    import("../components/userProfile/support/terminateAccount/CancelAccount")
  )
);

const TerminateSubscription = Loadable(
  lazy(() =>
    import("../components/dashboard/Subscription/TerminateSubscription")
  )
);

const RequestSubmitted = Loadable(
  lazy(() => import("../pages/RequestSubmitted"))
);

const UpdatePassword = Loadable(
  lazy(() =>
    import(
      "../components/userProfile/profileDetails/updatePassword/UpdatePassword"
    )
  )
);

const PassowrdResetLinkSent = Loadable(
  lazy(() =>
    import(
      "../components/userProfile/profileDetails/updatePassword/UpdatePasswordRequestSubmitted"
    )
  )
);

const DeliveryRequest = Loadable(
  lazy(() =>
    import(
      "../components/my_cellar/celler-delivery-flow/delivery-request/DeliveryRequest"
    )
  )
);

const DeliveryRequestSubmitted = Loadable(
  lazy(() =>
    import(
      "../components/my_cellar/celler-delivery-flow/delivery-request/DeliveryRequestSubmitted"
    )
  )
);

const BarDetails = Loadable(lazy(() => import("../components/bar/BarDetails")));
