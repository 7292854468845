import { SvgIcon } from "@mui/material";

export default function VisibilityOpenIcon(props) {
  return (
    <SvgIcon
      height={"24px"}
      width={"24px"}
      viewBox={"0 0 24 24"}
      fill={"none"}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 6C15.6054 6 18.6734 4.0949 20.5288 0.58778C20.716 0.234012 21.0771 0 21.4773 0C22.2066 0 22.7029 0.737072 22.3682 1.385C21.8726 2.34415 21.2936 3.21242 20.641 3.98079L22.7999 5.60001C23.2418 5.93138 23.3313 6.55818 22.9999 7.00001C22.6686 7.44184 22.0418 7.53138 21.5999 7.20001L19.2098 5.40744C18.1293 6.3147 16.9091 7.00081 15.5789 7.43859L17.1999 9.59999C17.5313 10.0418 17.4417 10.6686 16.9999 11C16.5581 11.3314 15.9313 11.2418 15.5999 10.8L13.4356 7.91421C12.9668 7.97109 12.4879 8 11.9999 8C11.512 8 11.0331 7.97109 10.5643 7.91421L8.39992 10.8C8.06855 11.2418 7.44175 11.3314 6.99992 11C6.55809 10.6686 6.46855 10.0418 6.79992 9.59999L8.42097 7.43859C7.09819 7.00325 5.8842 6.32234 4.80811 5.42259L2.40408 7.31188C1.96984 7.65313 1.34118 7.57776 0.999921 7.14352C0.658663 6.70929 0.734036 6.08063 1.16827 5.73937L3.37776 4.00297C2.71741 3.22915 2.13201 2.35339 1.63168 1.385C1.29692 0.737073 1.79324 0 2.52254 0C2.92276 0 3.28384 0.234011 3.471 0.587779C5.32643 4.0949 8.39448 6 11.9999 6Z"
        fill="white"
      />
    </SvgIcon>
  );
}
