// components
import { IconButtonAnimate } from "../../../components/animate";
import { UserIcon } from "../../../icons";

// routes
import { DASHBOARD_PATHS } from "src/routes/path";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  return (
    <>
      <IconButtonAnimate href={DASHBOARD_PATHS.profile}>
        <UserIcon sx={{ width: "20px", height: "20px" }} />
      </IconButtonAnimate>
    </>
  );
}
