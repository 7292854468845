// React
import { createContext, useReducer, useEffect } from "react";

// PropTypes
import PropTypes from "prop-types";

// axios
import axios, { setSession } from "src/utils/axios";
import axiosInstance from "src/utils/axios";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  subscription: null,
  newSignup: false,
};

const handlers = {
  START_LOADING: (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  STOP_LOADING: (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  INITIALIZE: (state, action) => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: isAuthenticated,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isAuthenticated: isAuthenticated,
      user,
    };
  },
  LOGOUT: (state, _) => {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  },
  REGISTER: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      user: payload,
      newSignup: true,
    };
  },
  PROFILE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      user: payload,
    };
  },
  SWITCHMEMBERSHIP: (state, action) => {
    return {
      ...state,
      // isAuthenticated: isAuthenticated,
      subscription: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  requestPasswordReset: () => Promise.resolve(),
  verifyResetToken: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  verifyAccount: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  requestOTP: () => Promise.resolve(),
  verifyOTP: () => Promise.resolve(),
  submitKYC: () => Promise.resolve(),
  addShippingDetails: () => Promise.resolve(),
  membershipSwitch: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function initialize() {
      const token = localStorage.getItem("access");
      if (!token) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
        return;
      }
      try {
        const response = await axios.get("/accounts/me/");
        const { data } = response;

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: data,
          },
        });
      } catch (error) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    }

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post("/accounts/login/", {
        email,
        password,
      });

      const { data } = response;
      const {
        user: { is_email_verified },
      } = data;
      if (!is_email_verified) {
        // trigger account activation email
        const activationRedirectUrl = `https://${window.location.host}/auth/login/verify/`;
        await axios.post(
          "/accounts/resend-verification/",
          { redirect_url: activationRedirectUrl },
          {
            headers: {
              Authorization: `Bearer ${data.access}`,
              "Content-Type": "application/json",
            },
          }
        );

        return response.data;
      }
      setSession(data);

      dispatch({
        type: "LOGIN",
        payload: { user: data.user, isAuthenticated: true },
      });
    } catch (error) {
      throw error;
    }
  };

  const verifyAccount = async (uid, token) => {
    const response = await axios.post(
      `/accounts/verify-email/${uid}/${token}/`
    );
    const { data } = response;
    if (data["success"] !== true) {
      const error = new Error("activation failed");
      error.response = { data: data };
      throw error;
    }
  };

  const verifyResetToken = async (uid, token) => {
    const response = await axios.post(
      `/accounts/password-reset-verify/${uid}/${token}/`
    );
    const { data } = response;
    if (data["success"] !== true) {
      throw new Error("password reset link verification failed");
    }
    return data;
  };

  const addShippingDetails = async (data) => {
    dispatch({ type: "START_LOADING" });
    const response = await axios.patch("/accounts/me/", {
      shipping_address: data,
    });
    return response;
  };

  const membershipSwitch = async (data) => {
    dispatch({ type: "START_LOADING" });
    const response = await axios.put("/accounts/subscriptions/", data);
    return response;
  };

  const resetPassword = async ({ uid, token, password, passwordConfirm }) => {
    const response = await axios.post(
      `/accounts/password-reset-confirm/${uid}/${token}/`,
      {
        new_password: password,
        confirm_password: passwordConfirm,
      }
    );
    const { data } = response;
    if (data["success"] !== true) {
      const error = new Error("error resetting password");
      error.response = { data };
      throw error;
    }
  };

  const logout = async ({pageReload = true}) => {
    try {
      await axiosInstance.post("/accounts/logout/");
      localStorage.clear();
      dispatch({
        type: "LOGOUT",
        payload: { isAuthenticated: false },
      });
      if (pageReload) {
        window.location.reload();
      }
    } catch (error) {
      console.log("Server error, please try again!", error);
    }
  };

  const register = async (_data) => {
    const activationRedirectUrl = `https://${window.location.host}/auth/login/verify/`;
    const response = await axios.post("/accounts/", {
      ..._data,
      redirect_url: activationRedirectUrl,
    });
    const { data } = response;
    dispatch({
      type: "REGISTER",
      payload: { user: data },
    });
  };

  const requestPasswordReset = async ({ email }) => {
    const redirectUrl = `https://${window.location.host}/auth/reset-password/`;
    const response = await axios.post("/accounts/password-reset-request/", {
      email,
      redirect_url: redirectUrl,
    });
    return response;
  };

  const requestOTP = async (type) => {
    dispatch({ type: "START_LOADING" });
    try {
      const response = await axios.post(
        "/accounts/one-time-password-request/",
        { request_type: type }
      );
    } catch (error) {
      console.log("error:", error);
      dispatch({ type: "STOP_LOADING" });
    }
    dispatch({ type: "STOP_LOADING" });
  };

  const verifyOTP = async (data) => {
    dispatch({ type: "START_LOADING" });
    await axios.post("/accounts/one-time-password-verify/", data);
  };

  const submitKYC = async (data) => {
    dispatch({ type: "START_LOADING" });

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "identity_document") {
        formData.append(key, data[key], data[key].name);
      } else {
        formData.append(key, data[key]);
      }
    });

    console.log(formData);

    try {
      const response = await axios.put("/accounts/kyc/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response from context:", response.data);
      // navigate("/subscription-activated");
    } catch (error) {
      console.error("Error submitting KYC:", error.response);
    }
  };

  useEffect(() => {
    const getSubcription = async () => {
      if (!state.isAuthenticated) {
        return;
      }
      try {
        const response = await axios.get("/accounts/subscriptions/");
        const { data } = response;
        dispatch({
          type: "SWITCHMEMBERSHIP",
          payload: {
            subscription: data,
          },
        });
      } catch (error) {
        console.log("subscription error", error);
      }
    };
    getSubcription();
  }, [state.isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        requestPasswordReset,
        verifyResetToken,
        resetPassword,
        verifyAccount,
        requestOTP,
        verifyOTP,
        submitKYC,
        addShippingDetails,
        membershipSwitch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
