import { SvgIcon } from "@mui/material";

export default function RemoveIcon({ width = "17px", height = "20px" }) {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 17 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24392 2.74412C6.4002 2.58784 6.61216 2.50004 6.83317 2.50004H10.1665C10.3875 2.50004 10.5995 2.58784 10.7558 2.74412C10.912 2.9004 10.9998 3.11236 10.9998 3.33337V4.16671H5.99984V3.33337C5.99984 3.11236 6.08763 2.9004 6.24392 2.74412ZM4.33317 4.16671V3.33337C4.33317 2.67033 4.59656 2.03445 5.0654 1.56561C5.53424 1.09677 6.17013 0.833374 6.83317 0.833374H10.1665C10.8295 0.833374 11.4654 1.09677 11.9343 1.56561C12.4031 2.03445 12.6665 2.67033 12.6665 3.33337V4.16671H14.3332L14.3338 4.16671H15.9998C16.4601 4.16671 16.8332 4.5398 16.8332 5.00004C16.8332 5.46028 16.4601 5.83337 15.9998 5.83337H15.1665V16.6667C15.1665 17.3297 14.9031 17.9656 14.4343 18.4345C13.9654 18.9033 13.3295 19.1667 12.6665 19.1667H4.33317C3.67013 19.1667 3.03424 18.9033 2.5654 18.4345C2.09656 17.9656 1.83317 17.3297 1.83317 16.6667V5.83337H0.999837C0.5396 5.83337 0.166504 5.46028 0.166504 5.00004C0.166504 4.5398 0.5396 4.16671 0.999837 4.16671H2.66587L2.6665 4.16671H4.33317ZM3.49984 5.83337V16.6667C3.49984 16.8877 3.58763 17.0997 3.74391 17.256C3.9002 17.4122 4.11216 17.5 4.33317 17.5H12.6665C12.8875 17.5 13.0995 17.4122 13.2558 17.256C13.412 17.0997 13.4998 16.8877 13.4998 16.6667V5.83337H3.49984ZM6.83317 8.33337C7.29341 8.33337 7.6665 8.70647 7.6665 9.16671V14.1667C7.6665 14.6269 7.29341 15 6.83317 15C6.37293 15 5.99984 14.6269 5.99984 14.1667V9.16671C5.99984 8.70647 6.37293 8.33337 6.83317 8.33337ZM10.9998 14.1667V9.16671C10.9998 8.70647 10.6267 8.33337 10.1665 8.33337C9.70627 8.33337 9.33317 8.70647 9.33317 9.16671V14.1667C9.33317 14.6269 9.70627 15 10.1665 15C10.6267 15 10.9998 14.6269 10.9998 14.1667Z"
        fill="white"
      />
    </SvgIcon>
  );
}
