import { useState } from "react";
import { useTheme } from "@mui/styles";

import {Snackbar, Alert, useMediaQuery} from "@mui/material";

export default function SnackbarNotifier({message, setError}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [showSnackbar, setShowSnackbar] = useState(true);

  const handleClose = () => {
    setShowSnackbar(false);
    setError(false)
  };

  return (
    <>
    <Snackbar
      sx={
        isDesktop
          ? { width: "100%", zIndex: "12000" }
          : {
              width: "90%",
              zIndex: "12000",
              margin: "auto !important",
            }
      }
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={showSnackbar}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        style={isDesktop ? { width: "60%" } : { width: "100%" }}
        severity="info"
        icon={false}
        sx={{ color: "white", backgroundColor: "#3E3E3E", margin:"auto", fontSize:'12px', fontWeight:400, fontFamily:"Minion Pro" }}
      >
        {message}
      </Alert>
    </Snackbar>
  </>

  );
}
