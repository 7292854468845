import { SvgIcon } from "@mui/material";

export default function SortIcon({
  size = "medium",
  isActive = false,
  sortField,
}) {
  const isDescendingOrder = (field) => field.charAt(0) === "-";
  const isDescOrder = isDescendingOrder(sortField || "");

  const wH = size === "small" ? "12px" : size === "large" ? "28px" : "24px";
  return (
    <SvgIcon
      sx={{ width: wH, height: wH }}
      viewBox={"0 0 24 24"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <path
        d="M18 14H6L12 20L18 14Z"
        fill={isActive && isDescOrder ? "white" : "#877564"}
        fillOpacity="0.4"
      />
      <path
        d="M18 10H6L12 4L18 10Z"
        fill={isActive && !isDescOrder ? "white" : "#877564"}
        fillOpacity="0.4"
      />
    </SvgIcon>
  );
}
