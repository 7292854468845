import { useState } from "react";

// yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// mui
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// routes
import { AUTH_PATHS } from "src/routes/path";

// hooks
import { FormProvider, VTextField } from "../form-hooks";
import { useForm } from "react-hook-form";
import useAuth from "src/hooks/useAuth";

// icons
import { VisibilityIcon } from "src/icons";
import VisibilityOpenIcon from "src/icons/VisibilityOpenIcon";
import useModal from "src/hooks/useModal";
import { useTheme } from "@mui/styles";

export default function LoginForm({ message }) {
  const [showPassword, setShowPassword] = useState(false);
  const [accountNotValid, setAccountNotValid] = useState(false);
  const [acountNotActivated, setAccountNotActivated] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { login } = useAuth();

  const {
    modalOpen: lockedAccountModalOpen,
    handleModalOpen: handleLockedAccountModalOpen,
    handleModalClose: handleLockedAccountModalClose,
  } = useModal();

  const handleIntercomOpen = () => {
    window.Intercom("showNewMessage");
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Please enter a valid email."),
    password: Yup.string().required("Please enter a valid password."),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const response = await login(data.email, data.password);
      if (!response.user.is_email_verified) {
        setAccountNotActivated(true);

        setTimeout(() => {
          setAccountNotActivated(false);
        }, 5000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAccountNotValid(true);

        setTimeout(() => {
          setAccountNotValid(false);
        }, 5000);
      }
      if (error.response.status === 403) {
        handleLockedAccountModalOpen();
      }
    }
  };

  return (
    <>
      <Modal
        open={lockedAccountModalOpen}
        onClose={handleLockedAccountModalClose}
        sx={{
          "& .MuiBackdrop-root": {
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(1px)",
          },
        }}
      >
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#262626",
            p: 4,
            borderRadius: "20px",
            width: !isDesktop ? "90%" : "30%",
          }}
          spacing={2}
        >
          <Stack>
            <Typography
              align="center"
              sx={{ fontSize: isDesktop ? "20px" : "16px", fontWeight: 400 }}
            >
              Account Locked
            </Typography>
            <Typography
              align="center"
              sx={{ fontSize: isDesktop ? "16px" : "12px", fontWeight: 400 }}
            >
              Your account has been locked.
            </Typography>
            <Typography
              align="center"
              sx={{ fontSize: isDesktop ? "16px" : "12px", fontWeight: 400 }}
            >
              Please contact us at{" "}
              <Link href="mailto:support@vinuage.com">support@vinuage.com</Link>{" "}
              for assistance.
            </Typography>
          </Stack>
          {/* <Stack>
            <Button variant="contained" onClick={handleIntercomOpen}>
              Contact Us
            </Button>
          </Stack> */}
          <Stack>
            <Button
              textAlign={"center"}
              fullWidth
              sx={{
                color: "white",
                border: "1px solid white",
                mb: 3,
                "&:hover": {
                  backgroundColor: "inherit",
                  boxShadow: "none",
                  cursor: "pointer",
                },
              }}
              onClick={handleLockedAccountModalClose}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <Typography
        fontFamily={"Zen Antique, serif"}
        mb={"8px"}
        textAlign={"center"}
        fontSize={32}
        variant={"subtitle1"}
      >
        Log in
      </Typography>
      <Typography mb={4}>
        to continue with Vinuage or{" "}
        <Link href={AUTH_PATHS.register}>Sign up</Link>
      </Typography>
      {accountNotValid && (
        <Paper sx={{ background: "#FF4A4A", maxWidth: "375px", mb: "50px" }}>
          <Typography sx={{ padding: "12px 16px" }}>
            Please ensure that your details are entered correctly.
          </Typography>
        </Paper>
      )}
      {acountNotActivated && (
        <Paper sx={{ background: "#FF4A4A", maxWidth: "375px", mb: "50px" }}>
          <Typography sx={{ padding: "12px 16px" }}>
            Account not activated. Please check your inbox to complete your
            account verification.
          </Typography>
        </Paper>
      )}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ maxWidth: "375px" }}>
          {message && (
            <Typography
              mb={"40px"}
              fontSize={12}
              sx={{
                width: "100%",
                borderRadius: "4px",
                color: "#FFFFFF",
                backgroundColor: "#9F9F9F",
                padding: "12px 16px",
              }}
            >
              {message}
            </Typography>
          )}
          <VTextField sx={{ mb: 2 }} label={"Email"} name={"email"} />
          <VTextField
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <Box sx={{ paddingTop: "20px" }}>
                        <VisibilityOpenIcon />
                      </Box>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? "text" : "password"}
            label={"Password"}
            name={"password"}
          />
          <Link href={AUTH_PATHS.forgotPassword}>Forgot your password?</Link>
          <LoadingButton
            loading={isSubmitting}
            type={"submit"}
            sx={{
              borderRadius: "8px",
              px: 9,
              py: 1.2,
              mt: "48px",
              alignSelf: "center",
              width: "100%",
            }}
            variant={"contained"}
          >
            Log in
          </LoadingButton>
        </Box>
      </FormProvider>
    </>
  );
}
