import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "src/redux/store";

import axios from "src/utils/axios";

const initialState = {
  isLoading: false,
  error: null,
  dashboardItems: [],
  dashboardItem: null,
};

const slice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getDashboardItemsSuccess(state, action) {
      const { data } = action.payload;
      state.isLoading = false;
      state.dashboardItems = data;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export function getAllDashboardItems() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/dashboards/layouts/");
      dispatch(slice.actions.getDashboardItemsSuccess(response));
    } catch (error) {
      console.log({ error });
      dispatch(slice.actions.hasError(error));
    }
  };
}
