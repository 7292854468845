import { SvgIcon } from "@mui/material";

export default function InfoIcon({ width, height }) {
  return (
    <SvgIcon
      sx={{ width: width, height: height }}
      viewBox="0 0 17 16"
      fill={"none"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49984 1.99996C5.18613 1.99996 2.49984 4.68625 2.49984 7.99996C2.49984 11.3137 5.18613 14 8.49984 14C11.8135 14 14.4998 11.3137 14.4998 7.99996C14.4998 4.68625 11.8135 1.99996 8.49984 1.99996ZM1.1665 7.99996C1.1665 3.94987 4.44975 0.666626 8.49984 0.666626C12.5499 0.666626 15.8332 3.94987 15.8332 7.99996C15.8332 12.05 12.5499 15.3333 8.49984 15.3333C4.44975 15.3333 1.1665 12.05 1.1665 7.99996Z"
        fill="#0D0D0D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25008 7.25004V4.2288L7.75008 4.2288V8.43794L8.06217 8.43794L8.06217 8.75004L12.2713 8.75004V7.25004L9.25008 7.25004Z"
        fill="#0E0E0E"
      />
    </SvgIcon>
  );
}
