import { SvgIcon } from "@mui/material";

export default function TopupIcon(props){
    return(
        <SvgIcon viewBox={"0 0 18 20"} {...props} fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0905 3.46738C13.6903 3.32343 14.3054 3.63851 14.5391 4.20936L14.5575 4.25444H9.81109L13.0905 3.46738ZM3.69508 4.25444C3.7792 4.18633 3.87945 4.13544 3.99172 4.1085L12.7405 2.0088C14.06 1.69211 15.4132 2.38528 15.9273 3.64114L16.2354 4.39395C16.9761 4.68696 17.5001 5.40951 17.5001 6.25444V6.813C17.0311 6.5417 16.526 6.3259 15.9937 6.17457C15.9555 5.93639 15.749 5.75444 15.5001 5.75444H3.66675C3.39061 5.75444 3.16675 5.9783 3.16675 6.25444V13.9211C3.16675 14.1973 3.39061 14.4211 3.66675 14.4211H7.75533C7.90657 14.951 8.12174 15.454 8.39196 15.9211H3.66675C2.56218 15.9211 1.66675 15.0257 1.66675 13.9211V6.25444C1.66675 5.14987 2.56218 4.25444 3.66675 4.25444H3.69508Z" fill="white"/>
            <g clipPath="url(#clip0_663_948)">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1668 8.83773C12.0958 8.83773 10.4168 10.5167 10.4168 12.5877C10.4168 14.6588 12.0958 16.3377 14.1668 16.3377C16.2379 16.3377 17.9168 14.6588 17.9168 12.5877C17.9168 10.5167 16.2379 8.83773 14.1668 8.83773ZM9.5835 12.5877C9.5835 10.0564 11.6355 8.00439 14.1668 8.00439C16.6981 8.00439 18.7502 10.0564 18.7502 12.5877C18.7502 15.119 16.6981 17.1711 14.1668 17.1711C11.6355 17.1711 9.5835 15.119 9.5835 12.5877Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.16675 12.5878C9.16675 9.82634 11.4053 7.58777 14.1667 7.58777C16.9282 7.58777 19.1667 9.82634 19.1667 12.5878C19.1667 15.3492 16.9282 17.5878 14.1667 17.5878C11.4053 17.5878 9.16675 15.3492 9.16675 12.5878ZM14.1667 9.25444C12.3258 9.25444 10.8334 10.7468 10.8334 12.5878C10.8334 14.4287 12.3258 15.9211 14.1667 15.9211C16.0077 15.9211 17.5001 14.4287 17.5001 12.5878C17.5001 10.7468 16.0077 9.25444 14.1667 9.25444Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.9168 11.8378V10.2307L13.4168 10.2307L13.4168 11.8378L11.8098 11.8378L11.8098 13.3378L13.4168 13.3378V14.9448L14.9168 14.9448L14.9168 13.3378H16.5239V11.8378L14.9168 11.8378Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_663_948">
            <rect width="10" height="10" fill="white" transform="translate(9.16675 7.58777)"/>
            </clipPath>
            </defs>
        </SvgIcon>
    )
}