// react router
import { Outlet } from "react-router-dom";
// mui
import { Box, Stack, Typography } from "@mui/material";

// custom components
import Logo from "src/components/Logo";

// styles
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import CookieConsent from "react-cookie-consent";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 0,
  lineHeight: 0,
  position: "absolute",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "50px",
}));

const MainHeader = () => {
  return (
    <HeaderStyle sx={{ p: 2 }}>
      <Stack spacing={2} alignItems={"center"} direction={"row"}>
        <Logo sx={{ display: "inline-block", width: 80, height: 40 }} />
      </Stack>
    </HeaderStyle>
  );
};

const MainFooter = () => {
  return (
    <Box>
      <Typography
        textAlign={"center"}
        fontSize={12}
        fontWeight={400}
        color={"#FAFAFA"}
        lineHeight={"16px"}
        py={2}
        sx={{
          marginTop: "10px",
          backgroundColor: "#6E6E6E",
        }}
      >
        Under the law of Hong Kong, intoxicating liquor must not be sold or
        supplied to a minor (under 18) in the course of business.
      </Typography>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="vinuageCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        We use cookies and similar technologies to personalize content, analyze
        website performance, and tailor and measure ads. Please refer to our
        Cookies Policy in our Privacy Policy for further details.
      </CookieConsent>
    </Box>
  );
};

export default function AuthLayout() {
  return (
    <Stack minHeight={1}>
      <MainHeader />

      <Outlet />
      <Box sx={{ flexGrow: 1 }} />
      <MainFooter />
    </Stack>
  );
}

export function AuthLayoutComp({ children }) {
  return (
    <Stack minHeight={1}>
      <MainHeader />

      {children}
      <Box sx={{ flexGrow: 1 }} />

      <MainFooter />
    </Stack>
  );
}
