import axios from "axios";

const HOST_API = process.env.REACT_APP_HOST_API || "http://localhost:8000/api";

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("access");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

export const setSession = ({ access, refresh }) => {
  if (access) {
    localStorage.setItem("access", access);
    localStorage.setItem("refresh", refresh);
  } else {
    localStorage.removeItem("access");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export default axiosInstance;

export function getListParam(stringParam) {
  return stringParam.split(",").map((param) => param.trim());
}

export function getStringParam(listParam) {
  return listParam.join(",");
}

export function serializeParams(params) {
  for (const key of Object.keys(params)) {
    if (Array.isArray(params[key])) {
      const values = params[key];
      params[key] = values.join(",");
    }
  }
  return params;
}
