import { useState } from "react";

const useDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return {
    drawerOpen,
    handleDrawerOpen,
    handleDrawerClose,
  };
};

export default useDrawer;
