import PropTypes, { node } from "prop-types";

// react hook form
import { Controller, useFormContext } from "react-hook-form";

// mui
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";

VRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default function VRadioGroup({
  name,
  label,
  options,
  sx,
  onChange,
  value,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx} {...other} error={!!error}>
          <RadioGroup {...field}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                label={option.label}
                value={option.value}
                control={<Radio />}
                sx={{ alignItems: "flex-start", mt: index > 0 ? 2 : 0 }}
              />
            ))}
          </RadioGroup>
          <FormHelperText sx={{ ml: 4, mt: 0 }}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
      {...other}
    />
  );
}
