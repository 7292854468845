import React, { useState } from "react";

const useModal = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  return {
    modalOpen,
    handleModalOpen,
    handleModalClose,
  };
};

export default useModal;
