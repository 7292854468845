import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const isoCountries = getCountries();

const uniqueCountryCallingCodes = new Set();
const countryData = [];

isoCountries.forEach((isoCode) => {
  const callingCode = getCountryCallingCode(isoCode);
  countryData.push({
    isoCode,
    name: countries.getName(isoCode, "en") || "", // Handle undefined names
    callingCode: callingCode,
  });
  if (!uniqueCountryCallingCodes.has(callingCode)) {
    uniqueCountryCallingCodes.add(callingCode);
  }
});

export const sortedCountryNames = countryData
  .filter((country) => country.name) // Remove countries with undefined names
  .sort((a, b) => a.name.localeCompare(b.name)); // Sort by country name

export const sortedCountryCallingCodes = [...uniqueCountryCallingCodes].sort();
