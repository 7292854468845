import React, { useState, useEffect } from "react";
import useAuth from "./useAuth";

const useResendOtpButton = () => {
  const [isDisabled, setDisabled] = useState(true);
  const [countDown, setCountDown] = useState(10);

  const { requestOTP } = useAuth();

  useEffect(() => {
    if (isDisabled) {
      const interval = setInterval(() => {
        setCountDown((prevCount) => prevCount - 1);
      }, 1000); // every 1s

      const timeout = setTimeout(() => {
        setDisabled(false);
        setCountDown(10);
      }, 10000); // every 10s

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [isDisabled]);

  const handleResend = async (e) => {
    await requestOTP("email");
    setDisabled(true);
  };

  return { isDisabled, handleResend, countDown };
};

export default useResendOtpButton;
