import { useState, useEffect } from "react";

// paths
import { AUTH_PATHS } from "src/routes/path";

// custom components
import LoadingScreen from "src/components/LoadingScreen";
import LoginForm from "src/components/auth/LoginForm";
import Page from "src/components/Page";

// hooks
import useAuth from "src/hooks/useAuth";
import { useLocation } from "react-router-dom";

// mui
import { Stack, Link, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function InvalidOrExpiredLinkView() {
  return (
    <>
      <Typography mb={"8px"} variant={"subtitle1"} fontSize={28}>
        This link has been used or expired.
      </Typography>
      <Typography>
          Your email address has already been verified.<br/>
          Please click the button below to log in to your account. Welcome to Vinuage!
      </Typography>
      <LoadingButton
        component={Link}
        href={AUTH_PATHS.login}
        loading={false}
        type={"submit"}
        sx={{
          borderRadius: "8px",
          px: 9,
          py: 1.2,
          mt: "48px",
          alignSelf: "center",
          width: "100%",
        }}
        variant={"contained"}
      >
        Log in
      </LoadingButton>
    </>
  );
}

export default function Login({ _message }) {
  const { login, verifyAccount } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLinkExp, setVerificationLinkExp] = useState(null);

  const location = useLocation();
  const navStateMessage = location.state?.message;
  const [message, setMessage] = useState(navStateMessage || _message);

  let { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  useEffect(() => {
    const verify = async () => {
      if (!(uid && token)) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        await verifyAccount(uid, token);
        setMessage("Your email address has been verified. Welcome to Vinuage!");
      } catch (error) {
        setVerificationLinkExp(true);
        console.log("error occurred verifying account:", error.response.data);
      } finally {
        setIsLoading(false);
      }
    };
    verify();
  }, [uid, token, verifyAccount]);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Page sx={{ p: 2 }} title={"Login"}>
      <Stack
        p={0}
        sx={{
          position: "relative",
          top: "50px",
          direction: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {verificationLinkExp === true ? (
          <InvalidOrExpiredLinkView />
        ) : (
          <LoginForm login={login} message={message} />
        )}
      </Stack>
    </Page>
  );
}
