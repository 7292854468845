import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const initialState = {
  showSnackbar: false,
  showSnackbarMessage: null,
  onSnackbarOpen: () => {},
  onSnackbarClose: () => {},
  setDisplayMessage: () => {},
  resetDisplayMessage: () => {},
};

const GlobalSnackbarContext = createContext(initialState);

const GlobalSnackbarProvider = ({ children }) => {
  const [openSnackbar, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const displaySnackbarMessage = (message) => {
    setSnackbarMessage(message);
  };

  const removeSnackbarMessage = () => {
    setSnackbarMessage(null);
  };

  return (
    <GlobalSnackbarContext.Provider
      value={{
        showSnackbar: openSnackbar,
        showSnackbarMessage: snackbarMessage,
        onSnackbarOpen: handleSnackbarOpen,
        onSnackbarClose: handleSnackbarClose,
        setDisplayMessage: displaySnackbarMessage,
        resetDisplayMessage: removeSnackbarMessage,
      }}
    >
      {children}
    </GlobalSnackbarContext.Provider>
  );
};

GlobalSnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export { GlobalSnackbarContext, GlobalSnackbarProvider };
