// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { useLocation } from "react-router";
import { useEffect } from "react";
import useAuth from "./hooks/useAuth";

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  const { user, isLoading } = useAuth();

  useEffect(() => {
    // Wait until the user data is available
    if (!isLoading && user) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xe2v18ku",
        name: user.username,
        email: user.email, // Pass the user's email
      });

      // Update Intercom whenever the location changes
      window.Intercom("update");
    }

    window.intercomSettings = {
      app_id: "xe2v18ku",
      hide_default_launcher: true,
    };

    // Clean up Intercom on component unmount
    return () => {
      window.Intercom("shutdown");
    };
  }, [location, user, isLoading]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ProgressBarStyle />
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
