import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

// hooks
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
// config
import { HEADER, NAVBAR } from "../../config";
//
import Navigation from "../../components/dashboard/Navigation";
import DashboardHeader from "src/layouts/dashboard/header";
import SwitchMembership from "../../components/dashboard/Subscription/SwitchMembership";

// ----------------------------------------------------------------------

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ _, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  paddingBottom: HEADER.MOBILE_HEIGHT + 30,
  [theme.breakpoints.up("md")]: {
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 100,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [_, setIsWinePoperIconClicked] = useState(false);
  const showHideCart = () => setIsWinePoperIconClicked((state) => !state);

  const { showSubscriptionPlanSelector } = useCollapseDrawer();

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader showHideCart={showHideCart} />
      <MainStyle>
        <SwitchMembership />
        <Outlet />
      </MainStyle>
      <Navigation />
    </Box>
  );
}
