// PropType
import PropTypes from "prop-types";

// mui
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

// react hook form
import { Controller, useFormContext } from "react-hook-form";

const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const useHelperTextStyles = makeStyles(() => ({
  root: {
    marginLeft: 0,
    color: "white",
    fontSize: "12px",
  },
  warning: {
    color: "red",
  },
}));

VTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default function VTextField({ name, InputProps, isHelperTextWarning, ...other }) {
  const classes = useStyles();
  const { control } = useFormContext();
  const helperTextStyles = useHelperTextStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          InputLabelProps={{ sx: { color: "text.primary" } }}
          InputProps={{ ...InputProps, classes }}
          variant={"filled"}
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          FormHelperTextProps={{
              classes: isHelperTextWarning ? { root: `${helperTextStyles.root} ${helperTextStyles.warning}` } : { root: helperTextStyles.root },
          }}
        />
      )}
    />
  );
}
