import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Avatar,
  Typography,
  ListItemText,
  ListItemAvatar,
  MenuItem,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// components
import Scrollbar from "../../../components/Scrollbar";
import MenuPopover from "../../../components/MenuPopover";
import BadgeStatus from "../../../components/BadgeStatus";
import { IconButtonAnimate } from "../../../components/animate";
import { TopupIcon } from "../../../icons";
import { useNavigate } from "react-router";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function WalletPopover() {
  const [open, setOpen] = useState(null);

  const navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    navigate("/dashboard/wallet/wallet-details");
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <TopupIcon sx={{ width: "20px", height: "20px" }} />
      </IconButtonAnimate>
    </>
  );
}
