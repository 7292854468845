import { useState, useEffect } from "react";

// mui
import { useTheme } from "@mui/material/styles";
import {
  Drawer,
  Box,
  useMediaQuery,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
// components
import { IconButtonAnimate } from "../../../components/animate";
import CloseIcon from "@mui/icons-material/Close";

// config
import { TobuyIcon, InfoIcon, TobuyNotificationIcon } from "../../../icons";
//
import CartDisplayItem from "src/components/dashboard/CartDisplayItem";

import { useDispatch, useSelector } from "src/redux/store";
import { getCartProducts } from "src/redux/slices/product";
import axiosInstance from "src/utils/axios";
import useDrawer from "src/hooks/useDrawer";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

export default function WinePopover() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPage, setIsPage] = useState(null);

  const { user } = useAuth();

  const [firstBuyTooltipOpen, setFirstBuyTooltipOpen] = useState(false);
  const [drawerTimeTooltipOpen, setDrawerTimeTooltipOpen] = useState(false);

  const [drawerTooltipFlag, setDrawerTooltipFlag] = useState(true);

  const {
    drawerOpen: winePopperDrawerOpen,
    handleDrawerOpen: handleWinePopperDrawerOpen,
    handleDrawerClose: handleWinePopperDrawerClose,
  } = useDrawer();

  const handleWinepopperDrawerCloseAndTimerTooltip = () => {
    handleWinePopperDrawerClose();
    setDrawerTooltipFlag(false);
    setDrawerTimeTooltipOpen(false);
  };

  const showTooltipAfterDelay = () => {
    if (firstBuyTooltipOpen) {
      setDrawerTimeTooltipOpen(true);

      setTimeout(() => {
        setDrawerTimeTooltipOpen(false);
      }, 100000);
    }
  };

  const hasTooltipBeenShown =
    localStorage.getItem("hasFirstBuyTooltipBeenShown") === "true";

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartProducts());
  }, [dispatch]);

  const { data } = useSelector((state) => state.product.cart);

  if (data && data.length > 0 && !hasTooltipBeenShown) {
    setFirstBuyTooltipOpen(true);
    localStorage.setItem("hasFirstBuyTooltipBeenShown", "true");

    setTimeout(() => {
      setFirstBuyTooltipOpen(false);
    }, 5000);
  }

  useEffect(() => {
    if (winePopperDrawerOpen) {
      const delayToShowTooltip = setTimeout(() => {
        if (drawerTooltipFlag) {
          showTooltipAfterDelay();
        }
      }, 1000);
      return () => clearTimeout(delayToShowTooltip);
    }
  }, [winePopperDrawerOpen]);

  const [cartExpiryTime, setCartExpiryTime] = useState(null);

  useEffect(() => {
    const fetchCartDetails = async () => {
      try {
        const response = await axiosInstance.get("/carts/");
        setCartExpiryTime(response.data.expired_at);
      } catch (error) {
        console.log("Error fetching cart details: ", error);
      }
    };

    fetchCartDetails();
  }, [data]);

  const [remainingTime, setRemainingTime] = useState("00:00:00"); // Initialize with a default value

  // Calculate the remaining time in HH:MM format
  function calculateRemainingTime() {
    if (!cartExpiryTime) {
      return "00:00:00"; // Return a default value if cartExpiryTime is not set
    }

    const currentTime = new Date();
    const timeDifference = new Date(cartExpiryTime) - currentTime;

    if (timeDifference <= 0) {
      // Handle the case when the time has already expired
      return "00:00:00";
    }

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (hours === 0) {
      // If remaining time is less than 1 hour, format as MM:SS
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");
      return `${formattedMinutes}:${formattedSeconds}`;
    }

    // Format hours, minutes, and seconds with leading zeros if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    // Update the remaining time every second if cartExpiryTime is set
    if (cartExpiryTime) {
      const intervalId = setInterval(() => {
        const newRemainingTime = calculateRemainingTime();
        setRemainingTime(newRemainingTime);
      }, 1000);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [cartExpiryTime]);

  const CartStyle = isMobile
    ? {
        bottom: "0px",
        borderRadius: "20px 20px 0 0",
        height: "500px",
        width: "100%",
      }
    : { right: 0, width: "400px", height: "100%" };

  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#F7E8CD",
              color: "black",
              "& .MuiTooltip-arrow": {
                color: "#F7E8CD",
              },
            },
          },
        }}
        title={
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              Added To Buy
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Click here to view your selected items.
            </Typography>
          </Box>
        }
        arrow
        open={firstBuyTooltipOpen}
        disableTriggerFocus={true}
      >
        <IconButtonAnimate
          onClick={handleWinePopperDrawerOpen}
          sx={{
            width: 40,
            height: 40,
            ...(isPage && { bgcolor: "action.selected" }),
          }}
        >
          {data && data.length !== 0 ? (
            <TobuyNotificationIcon />
          ) : (
            <TobuyIcon sx={{ width: "20px", height: "20px" }} />
          )}
        </IconButtonAnimate>
      </Tooltip>
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={winePopperDrawerOpen}
        onClose={handleWinepopperDrawerCloseAndTimerTooltip}
        sx={{ zIndex: "9999" }}
      >
        <Box
          {...CartStyle}
          sx={{
            backgroundColor: "#262626",
            p: 3,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Typography
                fontSize={20}
                fontWeight={400}
                fontFamily="Minion Pro"
                lineHeight={"24px"}
              >
                To Buy
              </Typography>
              {data && data.length > 0 && (
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#F7E8CD",
                        color: "black",
                        "& .MuiTooltip-arrow": {
                          color: "#F7E8CD",
                        },
                      },
                    },
                  }}
                  PopperProps={{
                    style: {
                      zIndex: 20000, // Set your desired z-index value
                    },
                  }}
                  title={
                    <Box>
                      <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                        Reservation Period
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        These items will be reserved for you until this
                        countdown timer reaches zero, after which your list will
                        be automatically reset. Please ensure that you proceed
                        with your purchase before then.
                      </Typography>
                    </Box>
                  }
                  placement={isMobile ? "top" : "bottom"}
                  arrow
                  open={drawerTimeTooltipOpen}
                  disableTriggerFocus={true}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "70px",
                      maxHeight: "24px",
                      backgroundColor: "#F7E8CD",
                      padding: "4px 6px",
                      borderRadius: "4px",
                    }}
                  >
                    <InfoIcon width={16} />
                    <Typography fontWeight={700} color="#0D0D0D" fontSize={12}>
                      {remainingTime}
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </Stack>
            <IconButton
              onClick={handleWinePopperDrawerClose}
              sx={{ color: "white" }}
            >
              <CloseIcon sx={{ width: "16px", height: "16px" }} />
            </IconButton>
          </Stack>
          <CartDisplayItem onClose={handleWinePopperDrawerClose} data={data} />
        </Box>
      </Drawer>
    </>
  );
}
