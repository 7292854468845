// react
import React, { useState, useEffect } from "react";

// mui
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Drawer,
  useMediaQuery,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// custom icons
import {
  CellarIcon,
  BottomNavBuywineIcon,
  SellIcon,
  BottomNavPortfolioIcon,
  BarIcon,
} from "src/icons";
import { HEADER } from "../../config";
import { useLocation, useNavigate } from "react-router";

// The array is subject to change.
const ICONS = [
  { id: 1, title: "Buy", icon: <BottomNavBuywineIcon />, path: "wine-listing" },
  { id: 2, title: "Cellar", icon: <CellarIcon />, path: "my-cellar" },
  {
    id: 3,
    title: "Portfolio",
    icon: <BottomNavPortfolioIcon />,
    path: "portfolio",
  },
  { id: 4, title: "Sell", icon: <SellIcon />, path: "listed-wines" },
  { id: 5, title: "Bar", icon: <BarIcon />, path: "bar-details" },
];

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "0",
  p: 0,
}));

const HideOnScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: false,
  });

  return (
    <Slide appear={false} direction={"up"} in={!trigger}>
      {children}
    </Slide>
  );
};

export default function Navigation() {
  const [selected, setSelected] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleSelected = (id) => {
    setSelected(id);
    if (id === 1) {
      navigate("/dashboard/wine-listing/");
    }
    if (id === 2) {
      navigate("/dashboard/my-cellar/");
    }
    if (id === 3) {
      navigate("/dashboard/portfolio/");
    }
    if (id === 4) {
      navigate("/dashboard/listed-wines/");
    }
    if (id === 5) {
      navigate("/dashboard/bar-details/");
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedIcon = ICONS.find((item) => currentPath.includes(item.path));
    if (selectedIcon) {
      setSelected(selectedIcon.id);
    }
  }, [location.pathname]);

  let containerSx = {
    position: isDesktop ? "fixed" : "sticky",
    left: "0",
    backgroundColor: "#0E0E0E",
  };

  containerSx = isDesktop
    ? {
        ...containerSx,
        mx: "auto",
        p: 2,
        top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        height: "100vh",
      }
    : { ...containerSx, bottom: 0, width: "100%" };

  const itemSx = { borderRadius: 1.3 };
  const itemSelected = isDesktop
    ? { ...itemSx, backgroundColor: "#262626", color: "#5E503F" }
    : {
        ...itemSx,
        color: "#877564",
      };
  const itemUnSelected = { ...itemSx, color: "rgba(255, 255, 255, 0.6)" };

  const MenuItemsContent = () => {
    return (
      <Box sx={containerSx}>
        <Stack
          direction={isDesktop ? "column" : "row"}
          justifyContent={"space-evenly"}
        >
          {ICONS.map((item) => (
            <IconButtonStyle
              key={item.id}
              sx={item.id === selected ? itemSelected : itemUnSelected}
              onClick={() => handleSelected(item.id)}
            >
              {item.icon}
              <Typography fontSize={10}>{item.title}</Typography>
            </IconButtonStyle>
          ))}
        </Stack>
      </Box>
    );
  };

  return isDesktop ? (
    <Drawer
      open={true}
      variant={"persistent"}
      PaperProps={{
        sx: {
          width: isDesktop ? "auto" : "100%",
          zIndex: 9999,
          borderRightStyle: "dashed",
          bgcolor: "background.default",
          transition: (theme) =>
            theme.transitions.create("width", {
              duration: theme.transitions.duration.standard,
            }),
        },
      }}
    >
      <MenuItemsContent />
    </Drawer>
  ) : (
      <React.Fragment>
          <HideOnScroll>
              <Box
                  sx={{
                      zIndex: 9999,
                      position: "fixed",
                      width: "100%",
                      bottom: "0",
                      backgroundColor: "#0E0E0E",
                  }}
              >
                  <MenuItemsContent />
              </Box>
          </HideOnScroll>
          <Box
              sx={{
                  zIndex: 9999,
                  width: "100%",
                  bottom: "0",
                  visibility: "hidden",
                  backgroundColor: "#0E0E0E",
              }}
          >
              <MenuItemsContent />
          </Box>
      </React.Fragment>

  );
}
