import useAuth from "../hooks/useAuth";
import { Navigate } from "react-router-dom";

import { DASHBOARD_PATHS } from "../routes/path";

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={DASHBOARD_PATHS.root} />;
  }
  return <>{children}</>;
};

export default GuestGuard;
