// mui
import { useTheme, styled } from "@mui/material/styles";
import {
  useMediaQuery,
  Modal,
  Typography,
  Button,
  Box,
  Stack,
} from "@mui/material";

const ModalStyle = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#262626",
  padding: "40px 32px",
}));

export default function RemoveItems({
  close,
  open,
  checkedItems,
  handleRemoveItem,
  getSelectedKeys,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Modal
        sx={{ zIndex: "12000 !important" }}
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isDesktop ? (
          <ModalStyle
            sx={{ width: "620px", height: "184px", borderRadius: "20px" }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Remove these {getSelectedKeys(checkedItems).length} items?
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="flex-end"
              mt={4}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "65px",
                  height: "56px",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={close}
              >
                Back
              </Button>
              <Button
                variant="contained"
                sx={{ width: "91px", height: "56px" }}
                onClick={() => handleRemoveItem(checkedItems)}
              >
                Confirm
              </Button>
            </Stack>
          </ModalStyle>
        ) : (
          <ModalStyle
            sx={{
              width: "326px",
              height: "256px",
              borderRadius: "20px",
            }}
          >
            {getSelectedKeys(checkedItems).length === 1 ? (
              <Typography textAlign="center" variant="h6" component="h2">
                Remove this {getSelectedKeys(checkedItems).length} item
              </Typography>
            ) : (
              <Typography textAlign="center" variant="h6" component="h2">
                Remove these {getSelectedKeys(checkedItems).length} items
              </Typography>
            )}
            <Stack
              mt={2}
              justifyContent="center"
              alignItems="flex-end"
              gap="16px"
            >
              <Button
                variant="contained"
                sx={{ width: "100%", height: "56px" }}
                onClick={() => handleRemoveItem(checkedItems)}
              >
                Confirm
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  height: "56px",
                  border: "1px solid white",
                  color: "white",
                }}
                onClick={close}
              >
                Back
              </Button>
            </Stack>
          </ModalStyle>
        )}
      </Modal>
    </Box>
  );
}
