// react
import { useState } from "react";

// mui
import { Menu, MenuItem, Box, Stack, Typography } from "@mui/material";

// icons
import CheckIcon from "@mui/icons-material/Check";
import { EditIcon } from "src/icons";
import { useForm } from "react-hook-form";
import axiosInstance from "src/utils/axios";
import { getCartProducts } from "src/redux/slices/product";
import { useDispatch } from "src/redux/store";

export default function ItemList({ row, handleItemUpdateSnackbar }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = async (event, index) => {
    setSelected({ ...selected, [index]: event.currentTarget.value });

    const itemId = row.id;
    const selectedLot = event.currentTarget.value;

    const updatedQuantity = { quantity: selectedLot };

    try {
      const response = await axiosInstance.put(
        `/carts/items/${row.id}/`,
        updatedQuantity
      );

      if (response.data.id) {
        // Check if the response data indicates success
        handleItemUpdateSnackbar(true);
      } else {
        handleItemUpdateSnackbar(false);
      }
      dispatch(getCartProducts());
    } catch (error) {
      console.error("Error updating item:", error);
      handleItemUpdateSnackbar(false);
    }
  };

  if (row.product.available_quantity === 0 && row.quantity === 1) {
    // Don't render the menu if available_quantity is 0
    return (
      <Typography
        fontSize={10}
        fontFamily="Minion Pro"
        color="rgb(216, 9, 152)"
        sx={{ textDecoration: "underline" }}
      >
        {selected[`selected-${row.id}`] || row.quantity} Lot
      </Typography>
    );
  }

  // Calculate the number of menu items based on available_quantity or 4, whichever is lower
  const maxMenuItems = Math.min(
    row.product.available_quantity + row.quantity,
    4
  );
  const MenuData = Array.from({ length: maxMenuItems }, (_, index) => ({
    number: index + 1,
  }));

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems={"center"}
      sx={{ "&:hover": { cursor: "pointer" } }}
    >
      <Typography
        fontSize={10}
        fontFamily="Minion Pro"
        onClick={handleClick}
        color="rgb(216, 9, 152)"
        sx={{ textDecoration: "underline" }}
      >
        {selected[`selected-${row.id}`] || row.quantity} Lots
      </Typography>
      <Box>
        <EditIcon />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: "9999",
          "& .MuiPaper-root": {
            minWidth: "80px",
            backgroundColor: "#3E3E3E",
            boxShadow: "none",
          },
        }}
      >
        {MenuData.map((item, index) => (
          <Box key={index}>
            <MenuItem
              id={row.id}
              key={row.id}
              value={item.number}
              onClick={(event) => handleSelected(event, `selected-${row.id}`)}
            >
              {item.number}
              {(selected[`selected-${row.id}`] || row.quantity) ===
              item.number ? (
                <CheckIcon sx={{ ml: 2, width: "15px" }} />
              ) : null}
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </Stack>
  );
}
