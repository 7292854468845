import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar } from "@mui/material";
// hooks
import cssStyles from "src/utils/cssStyles";
// config
import { HEADER } from "src/config";
// components
import Logo from "src/components/Logo";
//
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import WinePoper from "src/layouts/dashboard/header/WinePoper";
import WalletPopover from "src/layouts/dashboard/header/WalletPopover";
import NotificationsPopover from "src/layouts/dashboard/header/NotificationsPopover";
//
import VinuagePointIcon from "src/icons/VinuagePointIcon";
import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";
import PremiumLogoIcon from "src/icons/PremiumLogoIcon";
import formatPrice from "src/utils/formatPrice";
import { useLocation } from "react-router";
// ----------------------------------------------------------------------

const RootStyle = styled(
  AppBar,
  {}
)(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1201,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    backgroundColor: theme.palette.background.default,
  },
}));
// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({}) {
  const { user } = useAuth();

  const [balance, setBalance] = useState(user.wallet.balance);
  const location = useLocation();

  const userOnWalletPage =
    location.pathname === "/dashboard/wallet/wallet-details";
  const userOnOrderSummaryPage =
    location.pathname === "/dashboard/cart/checkout/";
  const userOnMyCellarPage = location.pathname === "/dashboard/my-cellar";

  const fetchWalletData = async () => {
    try {
      const response = await axiosInstance.get("/accounts/me/");
      setBalance(formatPrice(response.data.wallet.balance));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (user.subscription.plan_code === "FREE") {
      return;
    }

    fetchWalletData();

    const interval = setInterval(fetchWalletData, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userOnWalletPage || userOnMyCellarPage) {
      fetchWalletData();
    }
  }, [userOnWalletPage, userOnMyCellarPage]);

  return (
    <RootStyle>
      <Toolbar
        sx={{
          minHeight: "100% !important",
        }}
      >
        {user.subscription.plan_code === "PREMIUM" ? (
          <PremiumLogoIcon sx={{ width: 80, height: 40 }} />
        ) : (
          <Logo sx={{ width: 80, height: 40 }} />
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <NotificationsPopover /> */}
          <AccountPopover />
          {userOnOrderSummaryPage !== true && <WinePoper />}
          {user.subscription.plan_code !== "FREE" && <WalletPopover />}
          {user.subscription.plan_code !== "FREE" && (
            <Stack>
              <span style={{ fontSize: 10 }}>Balance</span>
              <span
                style={{ fontSize: 12, display: "flex", alignItems: "center" }}
              >
                <VinuagePointIcon />
                {balance}
              </span>
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
