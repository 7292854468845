import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Typography,
  Stack,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  Snackbar,
  Alert,
  useMediaQuery,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

// Icons
import { VinuagePointIcon, RemoveIcon } from "src/icons";

// components
import ItemList from "./ItemList";
import RemoveItems from "./RemoveItems";

// redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { removeCartProducts } from "src/redux/slices/product";
import DiscountTagIcon from "src/icons/DiscountTagIcon";
import formatPrice from "src/utils/formatPrice";
import useAuth from "src/hooks/useAuth";
import useGlobalSnackbar from "src/hooks/useGlobalSnackbar";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow: "0 0 0 2px white",
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(57,75,89,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#877564",
  boxShadow: "0 0 0 2px #877564",
  borderRadius: "4px",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

const TextStyle = styled(Typography)(({ theme }) => ({
  fontFamily: "Minion Pro",
  fontSize: "10px",
}));

const LoadingButtonStyle = styled(LoadingButton)(({ theme }) => ({
  borderRadius: "8px",
  height: "48px",
  p: "8px 16px",
  width: "100%",
  lineHeight: "24px",
  fontWeight: "700",
  fontSize: "15px",
  "& .MuiLoadingButton-root": {
    backgroundColor: "blue",
  },
}));

const ButtonContainerStyle = styled(Stack)(({ theme }) => ({
  position: "absolute",
  maxWidth: "90%",
  bottom: 0,
  width: "100%",
  marginBottom: "12px",
}));

const tableContentFont = {
  lineHeight: "16px",
  fontSize: "12px",
};

function ListingBodyRowSummaryItem({
  item,
  checked,
  change,
  editMode,
  handleItemUpdateSnackbar,
}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { user } = useAuth();

  return (
    <Table
      sx={{
        // "& .MuiTableCell-root": tableCellStyle,
        "& .MuiTableCell-body": tableContentFont,
        "& .MuiTableRow-root .MuiTableCell-root": {
          padding: "12px 8px",
        },
      }}
    >
      <TableHead />
      {item.length !== 0 && (
        <TableBody>
          {item.map((row, index) => (
            <React.Fragment key={row.id}>
              <TableRow>
                <TableCell
                  sx={{
                    borderLeft: `3px solid  #${row.product.type.color_code}`,
                    alignItems: "center",
                    lineHeight: "16px",
                    width: "40%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {editMode && (
                      <Checkbox
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        checked={checked[row.id]}
                        onChange={change}
                        name={row.id.toString()}
                      />
                    )}
                    <TextStyle>{row.product.name}</TextStyle>
                  </Box>

                  {row.product.discount.active && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          background: "white",
                          borderRadius: "4px",
                          padding: "2px",
                          textAlign: "center",
                          color: "#5B385B",
                        }}
                      >
                        <span style={{ marginRight: "2px" }}>
                          <DiscountTagIcon />
                        </span>
                        {row.product.discount.display_text}
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <TextStyle>
                    {row.product.vintage}, {row.product.source},{" "}
                    {row.product.packing}
                  </TextStyle>
                </TableCell>
                <TableCell>
                  {editMode ? (
                    <ItemList
                      row={row}
                      handleItemUpdateSnackbar={handleItemUpdateSnackbar}
                    />
                  ) : (
                    <TextStyle>
                      {row.quantity} {row.quantity === 1 ? "Lot" : "Lots"}
                    </TextStyle>
                  )}
                </TableCell>
                <TableCell>
                  {user.subscription.plan_code === "FREE" && (
                    <TextStyle>
                      ${" "}
                      {row.product.discount.active
                        ? formatPrice(row.product.discount.cash_price)
                        : formatPrice(row.product.cash_price)}
                    </TextStyle>
                  )}
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <VinuagePointIcon width="10px" height="auto" />
                    <TextStyle style={{ marginTop: 0, marginLeft: "1px" }}>
                      {row.product.discount.active
                        ? formatPrice(row.product.discount.coin_price)
                        : formatPrice(row.product.coin_price)}
                    </TextStyle>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={12}>
                  <Divider />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      )}
    </Table>
  );
}

export default function CartDisplayItem({
  data,
  onClose,
  isSubmitting = true,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = (event) => {
    setEditMode(true);
  };

  const [selectAll, setSelectAll] = useState(false);

  const [checkedItems, setCheckedItems] = useState();

  const [checkData, setCheckData] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [cartProductData, setCartProductData] = useState(data);

  useEffect(() => {
    if (data.length < 1) {
      setCheckData(true);
    } else {
      setCartProductData(data);
    }
    setCheckedItems(Object.fromEntries(data.map((item) => [item.id, false])));
  }, [data]);

  const handleChange = (event) => {
    const id = event.target.name;
    const isChecked = event.target.checked;

    if (id === "select-all") {
      setCheckedItems(
        Object.keys(checkedItems).reduce((items, item) => {
          items[item] = isChecked;
          return items;
        }, {})
      );
      setSelectAll(isChecked);
    } else {
      setCheckedItems({ ...checkedItems, [id]: isChecked });
      setSelectAll(
        Object.values({ ...checkedItems, [id]: isChecked }).every(
          (item) => item
        )
      );
    }
  };

  function getSelectedKeys(obj) {
    const selectedKeys = [];
    for (const key in obj) {
      if (obj[key]) {
        selectedKeys.push(key);
      }
    }
    return selectedKeys;
  }

  const handleRemoveItem = async (items) => {
    const selectedKeys = getSelectedKeys(items);
    setOpen(false);
    for (let i = 0; i < selectedKeys.length; i++) {
      const id = selectedKeys[i];
      await dispatch(removeCartProducts(id));
    }
    setSnackbarOpen(true);
  };

  const moveToCheckout = async () => {
    navigate("/dashboard/cart/checkout/");
    onClose();
  };

  const {
    showSnackbar: openItemUpdateSuccesSnackbar,
    showSnackbarMessage: ItemUpdateSuccessSnackbarMessage,
    onSnackbarOpen: handleItemUpdateSuccessSnackbarOpen,
    onSnackbarClose: handleItemUpdateSuccessSnackbarClose,
    setDisplayMessage: displayItemUpdateSuccessSnackbarMessage,
    resetDisplayMessage: resetItemUpdateSuccessSnackbar,
  } = useGlobalSnackbar();

  const handleItemUpdateSnackbar = (success) => {
    console.log(success);
    handleItemUpdateSuccessSnackbarOpen();
    if (success) {
      displayItemUpdateSuccessSnackbarMessage("Item Updated.");
    } else {
      displayItemUpdateSuccessSnackbarMessage(
        "Your selected items are temporarily unavailable, please try again later."
      );
    }
  };

  return (
    <>
      <RemoveItems
        open={open}
        close={handleClose}
        checkedItems={checkedItems}
        // removedData={removedData}
        handleRemoveItem={handleRemoveItem}
        getSelectedKeys={getSelectedKeys}
      />
      <Snackbar
        sx={{ position: "absolute", margin: "auto", width: "100%" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          style={{ width: "90%" }}
          severity="info"
          icon={false}
          sx={{ color: "white", backgroundColor: "#3E3E3E", mb: 7 }}
        >
          Items Removed
        </Alert>
      </Snackbar>
      <Snackbar
        open={openItemUpdateSuccesSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          handleItemUpdateSuccessSnackbarClose();
          resetItemUpdateSuccessSnackbar();
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ position: "absolute", margin: "auto", width: "100%" }}
      >
        <Alert
          style={{ width: "90%" }}
          severity="info"
          icon={false}
          sx={{ color: "white", backgroundColor: "#3E3E3E", mb: 7 }}
        >
          {ItemUpdateSuccessSnackbarMessage}
        </Alert>
      </Snackbar>
      {checkData ? (
        <Stack
          sx={{ height: "70%" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography>No Items</Typography>
        </Stack>
      ) : (
        <Box mt={4} sx={{ maxHeight: "70%", overflow: "auto" }}>
          <ListingBodyRowSummaryItem
            item={cartProductData}
            editMode={editMode}
            checked={checkedItems}
            change={handleChange}
            submitting={isSubmitting}
            handleItemUpdateSnackbar={handleItemUpdateSnackbar}
          />
        </Box>
      )}

      {!editMode
        ? isSubmitting && (
            <ButtonContainerStyle
              direction="row"
              justifyContent="space-between"
              gap="8px"
              mt={2}
            >
              <LoadingButtonStyle
                // href="#"
                loading={false}
                sx={{
                  color: "white",
                  border: "1px solid white",
                }}
                variant={"outlined"}
                onClick={handleEdit}
                disabled={checkData}
              >
                Edit
              </LoadingButtonStyle>
              <LoadingButtonStyle
                onClick={moveToCheckout}
                loading={false}
                variant={"contained"}
                disabled={checkData}
              >
                Confirm & Proceed
              </LoadingButtonStyle>
            </ButtonContainerStyle>
          )
        : isSubmitting && (
            <ButtonContainerStyle
              direction="row"
              justifyContent="space-between"
              gap="8px"
              mb={2}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <FormControlLabel
                  disabled={checkData}
                  control={
                    <Checkbox
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      checked={selectAll}
                      onChange={handleChange}
                      name="select-all"
                    />
                  }
                  label="Select All"
                />
                <Divider
                  sx={{ borderWidth: "1px", height: "23px" }}
                  orientation="vertical"
                />
                {Object.values(checkedItems).includes(true) && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: "75px",
                      height: "36px",
                      textAlign: "center",
                      "&:hover": {
                        cursor: "pointer",
                        background: "#4b403217",
                        borderRadius: "4px",
                      },
                    }}
                    onClick={handleOpen}
                    component={IconButton}
                    disabled={checkData}
                  >
                    <RemoveIcon />
                    <TextStyle
                      ml={1}
                      fontWeight={400}
                      lineHeight={"16px"}
                      sx={{ fontSize: "12px !important", color: "white" }}
                    >
                      Remove
                    </TextStyle>
                  </Stack>
                )}
              </Stack>
              <LoadingButtonStyle
                disabled={checkData}
                loading={false}
                variant="contained"
                ml={1}
                sx={{ width: "130px", height: "40px" }}
                onClick={(e) => setEditMode(false)}
              >
                Done
              </LoadingButtonStyle>
            </ButtonContainerStyle>
          )}
    </>
  );
}
