// mui
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// react hook form
import { Controller, useFormContext } from "react-hook-form";
import { makeStyles, useTheme } from "@mui/styles";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

export default function DatePickerInput({ name, ...other }) {
  const { control } = useFormContext();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            slotProps={{
              textField: {
                ...field,
                error: !!error,
                helperText: error?.message,
                ...other,
                fullWidth: true,
                variant: "filled",
                InputLabelProps: { sx: { color: "text.primary" } },
                InputProps: { classes },
              },
            }}
            value={field.value}
            onChange={(date) => field.onChange(date)}
            label="Date of birth"
            format="YYYY-MM-DD"
          />
        </LocalizationProvider>
      )}
    />
  );
}
