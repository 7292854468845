import { SvgIcon } from "@mui/material";

export default function DiscountTagIcon({ isWhite = false, ...rest }) {
  
  return (
    <SvgIcon
      sx={{ width: 10, height: 10 }}
      viewBox="0 0 10 10"
      fill={isWhite ? "white" : "none"}
      {...rest}
    >
      {isWhite ? (
        <path d="M9.705 4.79L5.205 0.29C5.025 0.11 4.775 0 4.5 0H1C0.45 0 0 0.45 0 1V4.5C0 4.775 0.11 5.025 0.295 5.21L4.795 9.71C4.975 9.89 5.225 10 5.5 10C5.775 10 6.025 9.89 6.205 9.705L9.705 6.205C9.89 6.025 10 5.775 10 5.5C10 5.225 9.885 4.97 9.705 4.79ZM5.5 9.005L1 4.5V1H4.5V0.995L9 5.495L5.5 9.005V9.005Z" />
      ) : (
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.705 5.79L6.205 1.29C6.025 1.11 5.775 1 5.5 1H2C1.45 1 1 1.45 1 2V5.5C1 5.775 1.11 6.025 1.295 6.21L5.795 10.71C5.975 10.89 6.225 11 6.5 11C6.775 11 7.025 10.89 7.205 10.705L10.705 7.205C10.89 7.025 11 6.775 11 6.5C11 6.225 10.885 5.97 10.705 5.79ZM3.28014 6.03613C2.89024 5.64623 2.89024 5.01215 3.28014 4.62225C3.67004 4.23235 4.30411 4.23235 4.69401 4.62225C5.08391 5.01215 5.08391 5.64623 4.69401 6.03613C4.30411 6.42603 3.67004 6.42603 3.28014 6.03613ZM3.81037 5.15249C3.7125 5.25035 3.7125 5.40803 3.81037 5.50589C3.90824 5.60376 4.06591 5.60376 4.16378 5.50589C4.26165 5.40803 4.26165 5.25035 4.16378 5.15249C4.06591 5.05462 3.90824 5.05462 3.81037 5.15249ZM5.32846 3.49991L5.32846 8.15594H6.32831V3.49991H5.32846ZM6.98011 6.80703C6.59021 6.41712 6.59021 5.78305 6.98011 5.39315C7.37001 5.00325 8.00409 5.00325 8.39399 5.39315C8.78389 5.78305 8.78389 6.41712 8.39399 6.80703C8.00409 7.19693 7.37001 7.19693 6.98011 6.80703ZM7.51035 5.92338C7.41248 6.02125 7.41248 6.17892 7.51035 6.27679C7.60822 6.37466 7.76589 6.37466 7.86376 6.27679C7.96162 6.17892 7.96162 6.02125 7.86376 5.92338C7.76589 5.82551 7.60822 5.82552 7.51035 5.92338Z"
        />
      )}
    </SvgIcon>
  );
}
