import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Typography,
  MenuItem,
  Radio,
  FormControl,
  useMediaQuery,
  Tooltip,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  FormProvider,
  VTextField,
  VSelect,
  VCheckbox,
  VRadioGroup,
} from "../../components/form-hooks";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Controller, useForm, useFormContext } from "react-hook-form";

import { sortedCountryNames } from "src/utils/countries";

import useAuth from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Logo from "src/components/Logo";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  fontFamily: "Minion Pro",
  fontStyle: "normal",
  fontWeight: 400,
  color: "#FFF",
};

const linkStyle = {
  color: "#D80998",
  textDecoration: "none",
};

const fontFamily = {
  fontFamily: "Minion Pro",
};

const ImageUpload = ({ onChange }) => {
  const { setValue, setError } = useFormContext();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setValue("identity_document", file);
    if (!file || !file.size) {
      setError("identity_document", {
        type: "manual",
        message: "Please upload your identity document.",
      });
    } else {
      // Clear the error if a file is selected
      setError("identity_document", null);
    }
    onChange && onChange(file); // Notify parent component if needed
  };

  return (
    <Box sx={{ paddingTop: "10px" }}>
      <Typography sx={{ mb: "5px" }}>
        Upload Photo/ Scanned Copy Of HKID Card/ Passport
      </Typography>
      <input
        type="file"
        accept="image/*,application/pdf"
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default function SubscriptionKYC() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { submitKYC } = useAuth();

  const countryRenderValue = (value) => {
    const selectedOption = sortedCountryNames.find(
      ({ isoCode }) => isoCode === value
    );
    return selectedOption.name;
  };

  const [bankCodes, setBankCodes] = useState([]);

  const fetchBankCodes = async () => {
    try {
      const response = await axiosInstance.get("/accounts/kyc/banks/");
      return response.data.results;
    } catch (error) {
      console.error("Error fetching bank codes:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchBankCodesData = async () => {
      const data = await fetchBankCodes();
      setBankCodes(data);
    };

    fetchBankCodesData();
  }, []);

  const bankCodeRenderValue = (value) => {
    const selectedOption = bankCodes.find((option) => option.code === value);
    return selectedOption.name;
  };

  const schema = Yup.object().shape({
    areaCode: Yup.string().required("Please enter a valid area code."),
    phone_number: Yup.string()
      .required("Please enter a valid phone number.")
      .test("is-number", "Please enter a valid phone number.", (value) => {
        return /^[0-9]+$/.test(value);
      }),
    nationality: Yup.string().required("Please enter your nationality."),
    identity_type: Yup.string().required("Please enter your ID."),
    identity_document: Yup.mixed()
      .required("Please upload your identity document.")
      .test(
        "fileSize",
        "File size is too large",
        (value) => !value || (value && value.size <= 1024 * 1024 * 100)
      ),
    hkid_number: Yup.string().when("identity_type", {
      is: "hkid",
      then: Yup.string().required("Please enter your HKID."),
    }),
    passport_nationality: Yup.string().when("identity_type", {
      is: "passport",
      then: Yup.string().required("Please enter your nationality."),
    }),
    passport_number: Yup.string().when("identity_type", {
      is: "passport",
      then: Yup.string().required("Please enter your passport number."),
    }),
    passport_last_name: Yup.string().when("identity_type", {
      is: "passport",
      then: Yup.string().required("Please enter your surname."),
    }),
    passport_first_name: Yup.string().when("identity_type", {
      is: "passport",
      then: Yup.string().required("Please enter your first name."),
    }),
    line_1: Yup.string().required("Please enter a valid address."),
    line_2: Yup.string(),
    city: Yup.string().required("Please enter your city."),
    country_or_region: Yup.string().required("Please enter your country."),
    postal_code: Yup.string(),
    bank_code: Yup.string().required("Please select a bank code."),
    bank_account_number: Yup.string().required(
      "Please enter a valid bank account number."
    ),
    fps_type: Yup.string()
      .oneOf(["fps_id", "phone_number"])
      .required("Please select your FPS type."),
    fps_id: Yup.string().when("fps_type", {
      is: "fps_id",
      then: Yup.string().required("Please enter your FPS ID."),
    }),
    fps_phone_number: Yup.string().when("fps_type", {
      is: "phone_number",
      then: Yup.string()
        .required("Please enter your FPS Mobile Number.")
        .test("is-number", "Please enter a valid phone number.", (value) => {
          return /^[0-9]+$/.test(value);
        }),
    }),
    referralCode: Yup.string(),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      "Please ensure that you have read, understand and agree to Vinuage's Terms & Conditions and Privacy Policy."
    ),
  });

  const defaultValues = {
    areaCode: "+852",
    phone_number: "",
    identity_type: "hkid",
    identity_document: null,
    hkid: "",
    nationality: "",
    passport_number: "",
    passport_nationality: "",
    passport_first_name: "",
    passport_last_name: "",
    line_1: "",
    line_2: "",
    city: "",
    country_or_region: "",
    postal_code: "",
    bank_code: "",
    bank_account_number: "",
    fps_type: "",
    fps_phone_number: "",
    fps_id: "",
    referralCode: "",
    termsAndConditions: false,
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    watch,
  } = methods;

  watch();

  const { identity_type, fps_type } = getValues();

  const onSubmit = async (_data) => {
    const data = {
      ..._data,
      phone_number: `${_data.areaCode}${_data.phone_number}`,
    };

    try {
      await submitKYC(data);
      navigate("/subscription-activated");
    } catch (error) {
      console.log("error:", error.response);
    }
  };

  return (
    <>
      <Box sx={{ pt: 2, pl: 2 }}>
        <Logo
          sx={{ alignSelf: "start", width: 80, height: 40 }}
          disabledLink={true}
        />
      </Box>
      <Stack
        spacing={3}
        sx={{
          textAlign: isDesktop ? "center" : "left",
          p: "24px",
          marginY: "16px",
        }}
      >
        <Typography
          variant="h3"
          sx={{ ...style, fontSize: "28px", lineHeight: "32px" }}
        >
          Membership Activation
        </Typography>
        <Stack spacing={2.5}>
          <Typography
            variant="p"
            sx={{ ...style, fontSize: "16px", lineHeight: "24px" }}
          >
            For security and KYC purposes, we ask our members to fill in the
            following details as part of their onboarding to activate their
            membership.
          </Typography>
          <Typography
            variant="p"
            sx={{ ...style, fontSize: "16px", lineHeight: "24px" }}
          >
            We treat your personal information and privacy very seriously.
            Please see our{" "}
            <Link
              href="https://www.vinuage.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              Privacy Policy{" "}
            </Link>
            for more details.
          </Typography>
        </Stack>
      </Stack>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack px={"24px"} alignItems={"center"} justifyContent={"center"}>
          <Stack spacing={2.5} sx={{ maxWidth: "375px" }}>
            <Stack
              spacing={1.5}
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                paddingBottom: "24px",
              }}
            >
              <Stack direction="row" spacing={1.5}>
                <Controller
                  name={"areaCode"}
                  label={"Area Code"}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      inputProps={{
                        name: "areaCode",
                        required: true,
                        autoFocus: true,
                      }}
                      inputStyle={{
                        color: "white",
                        background: "#2e2f30",
                        width: "100px",
                        height: "56px",
                      }}
                      containerStyle={{ width: "100px", border: "none" }}
                      buttonStyle={{
                        width: "40px",
                        background: "#2e2f30",
                        height: "56px",
                      }}
                      dropdownStyle={{
                        background: "#2e2f30",
                        color: "white",
                        height: "200px",
                      }}
                      searchStyle={{ color: "black" }}
                      country={"hk"}
                      value={field.value} // Use the value from react-hook-form
                      onChange={(value) => field.onChange(value)} // Pass the onChange function from react-hook-form
                    />
                  )}
                />
                <VTextField
                  label={"Mobile Phone Number"}
                  name={"phone_number"}
                  type="tel"
                  InputLabelProps={fontFamily}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    onInvalid: (e) => e.preventDefault(),
                  }}
                />
              </Stack>
              <VSelect
                renderValue={countryRenderValue}
                InputLabelProps={fontFamily}
                label={"Nationality/ Citizenship"}
                name={"nationality"}
              >
                {sortedCountryNames.map(({ isoCode, name }, index) => (
                  <MenuItem key={index} value={isoCode}>
                    <Stack
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Box>{name}</Box>
                      <Radio />
                    </Stack>
                  </MenuItem>
                ))}
              </VSelect>
            </Stack>
            <Stack
              spacing={1.5}
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                paddingBottom: "24px",
              }}
            >
              <FormControl>
                <VRadioGroup
                  name={"identity_type"}
                  options={[
                    {
                      label: (
                        <Box sx={{ mt: 1 }}>
                          <Typography>HKID Card</Typography>
                        </Box>
                      ),
                      value: "hkid",
                    },
                    {
                      label: (
                        <Box sx={{ mt: 1 }}>
                          <Typography>Passport</Typography>
                        </Box>
                      ),
                      value: "passport",
                    },
                  ]}
                />
              </FormControl>

              {identity_type === "hkid" && (
                <VTextField
                  label={"HKID Card Number"}
                  name={"hkid_number"}
                  InputLabelProps={fontFamily}
                />
              )}

              {identity_type === "passport" && (
                <>
                  <VTextField
                    label={"Nationality"}
                    name={"passport_nationality"}
                    InputLabelProps={fontFamily}
                  />{" "}
                  {/* TODO: Seek clarification */}
                  <VTextField
                    label={"Passport Number"}
                    name={"passport_number"}
                    InputLabelProps={fontFamily}
                  />
                  <Stack direction="row" spacing={2}>
                    <VTextField
                      label={"Surname(s)"}
                      name={"passport_last_name"}
                      InputLabelProps={fontFamily}
                    />
                    <VTextField
                      label={"Given Name(s)"}
                      name={"passport_first_name"}
                      InputLabelProps={fontFamily}
                    />
                  </Stack>
                </>
              )}
              <Controller
                name="identity_document"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <>
                    <ImageUpload onChange={field.onChange} />
                    {fieldState.error && (
                      <Typography sx={{ color: "#FF4842", fontSize: "12px" }}>
                        {fieldState.error.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Stack>
            <Stack
              spacing={1.5}
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                paddingBottom: "24px",
              }}
            >
              <Typography
                sx={{ ...style, fontSize: "16px", lineHeight: "24px" }}
              >
                Residential Address
              </Typography>

              <VTextField
                label={"Address Line 1"}
                name={"line_1"}
                InputLabelProps={fontFamily}
              />
              <VTextField
                label={"Address Line 2"}
                name={"line_2"}
                InputLabelProps={fontFamily}
              />
              <VTextField
                label={"City"}
                name={"city"}
                InputLabelProps={fontFamily}
              />
              <VSelect
                renderValue={countryRenderValue}
                InputLabelProps={fontFamily}
                label={"Country/ Region"}
                name={"country_or_region"}
              >
                {sortedCountryNames.map(({ isoCode, name }, index) => (
                  <MenuItem key={index} value={isoCode}>
                    <Stack
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Box>{name}</Box>
                      <Radio />
                    </Stack>
                  </MenuItem>
                ))}
              </VSelect>
              <VTextField
                label={"Postal Code"}
                name={"postal_code"}
                InputLabelProps={fontFamily}
              />
            </Stack>
            <Stack
              spacing={1.5}
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                paddingBottom: "24px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ ...style, fontSize: "16px", lineHeight: "24px" }}
                >
                  HKSAR Bank A/C
                </Typography>
                <Tooltip
                  title="This info is necessary for our KYC/ AML procedures and will be designated as your default account to receive payments/ transfers from Vinuage."
                  enterTouchDelay={0}
                >
                  <InfoOutlinedIcon
                    sx={{
                      width: "10px",
                      height: "10px",
                      marginTop: "2px",
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              </Box>
              <VSelect
                renderValue={bankCodeRenderValue}
                InputLabelProps={fontFamily}
                label={"Bank Code"}
                name={"bank_code"}
                sx={{
                  "& .MuiSelect-select": {
                    whiteSpace: "normal",
                  },
                }}
              >
                {bankCodes.map(({ id, code, name }) => (
                  <MenuItem
                    key={id}
                    value={code}
                    sx={{
                      fontSize: "14px",
                      width: "100%",
                      whiteSpace: "normal",
                    }}
                  >
                    <Stack
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Box>
                        {code} : {name}
                      </Box>
                      <Radio />
                    </Stack>
                  </MenuItem>
                ))}
              </VSelect>
              <VTextField
                label={"Bank A/C No."}
                name={"bank_account_number"}
                InputLabelProps={fontFamily}
              />
            </Stack>
            <Stack
              spacing={1.5}
              sx={{
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                paddingBottom: "24px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ ...style, fontSize: "16px", lineHeight: "24px" }}
                >
                  HKSAR FPS
                </Typography>
                <Tooltip
                  title="This will be designated as your backup account to receive payments/ transfers from Vinuage."
                  enterTouchDelay={0}
                >
                  <InfoOutlinedIcon
                    sx={{
                      width: "10px",
                      height: "10px",
                      marginTop: "2px",
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              </Box>
              <FormControl>
                <VRadioGroup
                  sx={{ display: "flex", width: "50%" }}
                  name={"fps_type"}
                  options={[
                    {
                      label: (
                        <Box sx={{ mt: 1 }}>
                          <Typography>Mobile No.</Typography>
                        </Box>
                      ),
                      value: "phone_number",
                    },
                    {
                      label: (
                        <Box sx={{ mt: 1 }}>
                          <Typography>FPS ID</Typography>
                        </Box>
                      ),
                      value: "fps_id",
                    },
                  ]}
                />
              </FormControl>
              {fps_type === "phone_number" && (
                <VTextField
                  label={"FPS Mobile No."}
                  name={"fps_phone_number"}
                  type="tel"
                  InputLabelProps={fontFamily}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    onInvalid: (e) => e.preventDefault(),
                  }}
                />
              )}
              {fps_type === "fps_id" && (
                <VTextField
                  label={"FPS ID"}
                  name={"fps_id"}
                  InputLabelProps={fontFamily}
                />
              )}
            </Stack>
            <Stack spacing="40px" sx={{ pb: 2 }}>
              <VTextField
                label={"Referral Code (if applicable)"}
                name={"refCode"}
                InputLabelProps={fontFamily}
              />
              <VCheckbox
                sx={{ ml: 2, mb: 2 }}
                name={"termsAndConditions"}
                label={
                  <Typography sx={fontFamily}>
                    I have read, understand, and agree to Vinuage’s{" "}
                    <Link
                      href="https://www.vinuage.com/t-c"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={linkStyle}
                    >
                      Terms and Conditions
                    </Link>
                    &nbsp; and &nbsp;
                    <Link
                      href="https://www.vinuage.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={linkStyle}
                    >
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                }
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{
                  color: "#FFF",
                  paddingY: 0,
                  height: "46px",
                }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  );
}
