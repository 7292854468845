import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  styled,
} from "@mui/material";

VCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  labelPlacement: PropTypes.oneOf(["end", "start", "top", "bottom"]),
  checked: PropTypes.bool,
};

export default function VCheckbox({
  name,
  label,
  labelPlacement,
  checked,
  sx,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx} {...other} error={!!error}>
          <FormControlLabel
            {...field}
            labelPlacement={labelPlacement}
            label={label}
            sx={{ ml: 0 }}
            control={<Checkbox checked={checked} name={name} />}
          />
          <FormHelperText sx={{ ml: 4, mt: 0 }}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
      {...other}
    />
  );
}
