import { SvgIcon } from "@mui/material";

export default function BarIcon({width, height}) {
  return (
    <SvgIcon sx={{width: width, height: height}} viewBox="0 0 25 24" fill={"none"}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01869 2.5H8.09982H16.0998H17.181L17.5228 3.52566L18.0736 5.17806C19.1432 8.38663 17.9329 11.9141 15.1188 13.7901L14.0413 14.5085C13.6719 14.7547 13.2674 14.9236 12.8498 15.0151V20.25H15.0998V21.75H9.09982V20.25H11.3498V15.0151C10.9323 14.9236 10.5277 14.7547 10.1584 14.5085L9.08087 13.7901C6.26677 11.9141 5.05648 8.38663 6.126 5.17806L6.6768 3.52566L7.01869 2.5ZM8.09982 4H16.0998L16.6506 5.6524C17.5062 8.21926 16.538 11.0412 14.2867 12.5421L13.2092 13.2604C12.5374 13.7083 11.6622 13.7083 10.9904 13.2604L9.91292 12.5421C7.66164 11.0412 6.69341 8.21926 7.54902 5.6524L8.09982 4Z"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}
